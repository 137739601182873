.ForgotPassword {
  .container {
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;

    .Login__form {
      padding: 75px 20px 0;
      padding-top: 75px;
      display: flex;
      flex-direction: column;
      gap: 30px;
    }

    .ForgotPassword__inner {
      // padding: 30px 0;
      min-height: 100vh;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .themeBrand {
        display: block;
        width: 250px;
        margin: 0 auto;
      }

      .ForgotPassword__title {
        text-align: center;
        padding-top: 54px;
      }

      .ForgotPassword__form{
        padding-top: 75px;

        input{
          outline: none;
        }
      }

      .form-group {
        display: flex;
        align-items: center;
        gap: 15px;
        border-bottom: 1px solid #333333;
        padding-bottom: 15px;

        input {
          background-color: transparent !important;
          border: 0;
          flex: 1;

          &:-internal-autofill-selected {
            background-color: transparent !important;
          }
        }
      }

      .forgotPass {
        text-align: end;
        text-decoration: none;
        color: #333333;
        font-weight: 800;
      }
    }

    .ForgotPassword__btn {
      display: flex;
      justify-content: center;
      padding-top: 20px;

      button {
        padding: 8px 20px !important;
        min-width: 153px;
        text-align: center;
        justify-content: center;
        border: none;
        border-radius: 7px;
        background-color: #333333;
        color: #fff;
      }
    }
    .ForgotPassword__footer {
      padding-top: 40px;
      p {
        text-align: center;

        a {
          text-decoration: none;
          font-weight: 800;
          color: #333333;
        }
      }
    }
  }
}
