.partners_slider_title {
  padding-bottom: 15px;
}

.partners-slider {
  background: transparent;
  padding: 40px 0px;

  .separator {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem 0;

    .line {
      width: 100%;
      height: 1px;
      background-color: #d1d5db; // Light gray
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .flower {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2rem;
      height: 2rem;
      background-color: black; // Pink
      color: white;
      font-size: 1.5rem;
      border-radius: 50%;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    }
  }

  .partners-slider .slick-slider {
    background: transparent;
  }

  .partners-slider .slick-list {
    background: transparent;
  }

  .partners-slider .slick-track {
    background: transparent;
  }

  .partners-slider .slick-slide {
    background: transparent;
  }

  .partners-slider .slick-dots {
    background: transparent;
  }

  .partners-slider .slick-dots li button:before {
    color: #666;
    opacity: 0.5;
  }

  .partners-slider .slick-dots li.slick-active button:before {
    color: #333;
    opacity: 1;
  }

  .categorySlider__img {
    width: 200px;
    height: 100px;
    background: transparent;
    padding: 0 10px;
  }

  .categorySlider__img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    background: transparent;
  }

  .slider-item {
    background: transparent;
  }

  .slick-dots {
    position: unset !important;
    padding-top: 10px;
  }
}

@media (max-width: 1080px) {
  .partners-slider {
    .categorySlider__img {
      padding: 0 20px;
    }
  }
}
