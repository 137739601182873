.header {
  padding: 40px 0;

  .header-icons {
    .tooltip-container {
      .tooltip-trigger span button {
        cursor: pointer;
      }
    }

    .select-dropdown {
      margin-top: 10px;
      [role="listbox"] {
        padding: 0;
        border: 1px solid #000;
        border-radius: 4px;
        [aria-selected="false"] {
          /* Styles for unselected options */
          background-color: #f9f9f9;
          color: #333;
          cursor: pointer;
        }

        [aria-selected="true"] {
          /* Styles for selected options */
          background-color: black;
          color: #fff;
          font-weight: bold;
        }

        [aria-selected="false"]:hover {
          /* Hover effect for unselected options */
          background-color: #e0e0e0;
        }
      }
    }
  }
}
.select-dropdown {
  position: absolute;
  right: 0px;
  top: 100%;
  width: 150px;
  z-index: 1000;
}
.header-icons {
  position: relative;
  .btn {
    height: 40px;
    width: 40px;
    min-width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff !important;
    border-radius: 15px;
    padding: 0 !important;
    svg {
      height: 28px;
      width: 28px;
      path {
        fill: #333333;
      }
    }
    &.border-btn {
      background: #333333;

      svg path {
        fill: #333333 !important;
      }

      &:hover {
        background-color: #000;
        svg path {
          fill: #fff !important;
        }
      }
    }
    &:hover {
      background-color: #333333 !important;
      svg path {
        fill: #fff;
      }
    }
  }
}
.notification-btn {
  color: white;
  display: inline-block;
  position: relative;
  padding: 2px 5px;
}
.notification-count {
  background-color: #333333;
  border-radius: 7px;
  color: white;
  padding: 1px 5px;
  font-size: 10px;
  position: absolute;
  top: -3px;
  right: -2px;
}
.filter-count {
  background-color: #333333;
  border-radius: 7px;
  color: white;
  padding: 1px 5px;
  font-size: 10px;
  position: absolute;
  top: 1px;
  right: 45px;
}
.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 15px;
  h2 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    padding: 0 !important;
  }
  .close-btn {
    border: 1px solid #a9a9a942;
    border-radius: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    font-size: 15px;
  }
}
.dialogContent {
  max-height: calc(100vh - 300px);
  min-height: 150px;
  padding: 10px 10px 10px 0px !important;
  .content-item-container {
    display: flex;
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
    .itemsBox {
      width: 100%;
      &__title {
        margin: 0;
        font-size: 14px;
        margin-bottom: 5px;
      }
    }
    .item-avatar {
      width: 35px;
      height: 35px;
      // position: relative;
      // top: -20px;
      margin-right: 12px;
    }
    .item-data {
      border: 1px solid #e9e9e9;
      padding: 8px 10px;
      border-radius: 2px;
      font-size: 12px;
      margin: 5px 0;
      display: flex;
      flex-direction: column;
    }
    .item-data-time {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 4px;
      p {
        margin: 0;
        font-size: 10px;
        line-height: normal;
      }
    }
  }

  .mark-as-read {
    border: 1px solid;
    border-radius: 5px;
    &:hover {
      background: #dfdfdf;
      cursor: pointer;
    }
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar {
    width: 4px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #000000;
    border: 2px solid #555555;
  }
}
.dialogActions {
  display: flex !important;
  justify-content: space-between !important;
  border-top: 1px solid #bdbdbd !important;
  padding: 16px 18px !important;
}
.Tabbing {
  padding: 0 20px;
  &__btn {
    min-height: unset !important;
    .MuiTabs-flexContainer {
      gap: 10px;
      .MuiButtonBase-root {
        min-height: unset;
        color: #000 !important;
        padding: 10px 0;
        font-size: 12px;
        min-width: unset;
      }
    }
    .MuiTabs-indicator {
      background-color: #000;
      height: 1px;
    }
  }
  &__details {
    padding: 0 !important;
  }
}
.itemsBoxInner {
  display: grid;
  grid-template-columns: 60px 1fr 20px;
  gap: 15px;
  margin: 5px 0;
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 4px;
  align-items: flex-start;

  &__img {
    img {
      width: 100%;
      height: auto;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    p {
      margin: 0;
      font-size: 14px;
      line-height: normal;
      color: #000;
    }
  }
  button {
    background-color: transparent;
    width: 18px;
    height: 18px;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    img {
      width: 100%;
    }
  }
}
.bottomMobileIcons {
  background: transparent;
  padding: 0 20px 25px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 11;
  display: none;

  @media only screen and (max-width: 767px) {
    display: block;
  }
  &__inner {
    border: 1px solid #cccccc;
    border-radius: 17px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2px;
    background-color: #fff;

    .btn {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      width: 50px;
      border-radius: 15px;
      background: #fff;
      border: none;
      transition: all 0.3s ease;
      svg {
        height: 28px;
        width: 28px;
        path {
          fill: #333333;
        }
      }
      &.active {
        background: #333333;
        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
  }
}
.notification-dialog {
  // .MuiModal-backdrop {
  //   background: transparent;;
  // }

  .NotificationMainWrapper {
    padding: 0;
  }

  .dialog-header {
    padding: 0;
  }
  .Tabbing__btn {
    .MuiTabs-flexContainer {
      .MuiButtonBase-root {
        padding: 10px;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .MuiDialog-paper {
      height: 100vh !important;
      width: 100vw !important;
      max-width: 100% !important;
      margin: 0 !important;
      left: 0;
      right: 0 !important;
      top: 0 !important;
      bottom: 0 !important;
      max-height: 100vh;
      border-radius: 0 !important;
      overflow: auto !important;
    }
  }
}
.profile-img {
  border-radius: 100px;
  width: 45px;
  height: 45px;
  object-fit: cover;
}
.profileBtn {
  width: 45px;
  height: 45px;
  padding: 0;
  background: #f5f5f5;
  border-radius: 100px;
  svg {
    height: 32px;
    width: 32px;
  }
}

header {
  .container .header_inner_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .search-container.search-container-mobile {
    display: none !important;
  }

  .profileBtn {
    border: none;
    cursor: pointer;
    justify-content: center;
    align-items: center;
  }

  .themeBrand {
    width: 150px;
    cursor: pointer;
  }

  .header-icons {
    align-items: center;
    .btn {
      border: 0;
    }
  }

  .header-icons {
    display: flex;
    gap: 20px;
  }

  .search-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    background-color: #f5f5f5;
    padding: 13px 10px;
    border-radius: 9px;
    width: 830px;

    input {
      border: none;
      background-color: transparent;
      font-size: 14px;
      width: calc(100% - 48px);
      outline: none;
    }

    .search-span-icon {
      font-size: 24px;
      color: #bdbdbd;
    }

    .filter_btn {
      padding: 0;
      border: none;
      background: transparent;

      svg,
      path {
        fill: #333333;
      }
    }
  }

  .profile_btn_container {
    height: 45px;
    span {
      height: 45px;
      display: block;
    }
  }
}

@media (max-width: 1440px) {
  header {
    .search-container {
      width: 450px;
    }
  }
}

@media (max-width: 1080px) {
  header .search-container {
    width: 340px;
  }
}

@media (max-width: 767px) {
  .modal-content {
    padding: 16px;
    .css-uodban {
      margin: 150px auto;
    }
    .MuiTypography-h6 {
      font-size: 14px !important;
    }
    .MuiTypography-body1 {
      font-size: 14px;
      margin-top: 8px;
    }
    .MuiButton-root {
      margin-top: 8px;
    }
  }

  header {
    &.header {
      padding-bottom: 20px;
    }

    .container {
      display: grid;
      gap: 15px;
    }
    .search-container {
      display: none;
    }

    .header-icons {
      gap: 10px;
    }

    .d-sm-none.language_container {
      display: flex !important;
    }

    .search-container.search-container-mobile {
      display: flex !important;
      width: 100%;
    }
  }
  .d-sm-none {
    display: none !important;
  }
}

.modal-content {
  &.css-uodban {
    margin: 150px auto;
  }
}
