.PaymentMainWrapper {
  padding: 50px 0;
}

.payment-methods {
  display: flex;
  align-items: center;
  gap: 10px;
  overflow: auto;
  margin-bottom: 30px;
  padding: 10px 0;
}
.payment-button {
  padding: 11px 17px;
  border: 1px solid #e2e2e2;
  background-color: #f5f5f5;
  border-radius: 10px;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
  font-weight: 400;
  white-space: noWrap;
  cursor: pointer;

  &:hover {
    color: white;
    background-color: rgb(34, 34, 34);
  }
  &.active {
    background-color: black;
    color: #fff;
  }
}
.GreenContent {
  font-size: 14px;
  line-height: 14px;
  color: #319f43;
  margin: 0;
  @media only screen and (max-width: 575px) {
    font-size: 12px;
  }
}
.CardSlider {
  margin: 20px 0 30px;
  &__inner {
    padding: 0 10px;
    @media only screen and (max-width: 575px) {
      padding: 0 10px 0 0;
    }
    img {
      width: 100%;
    }
  }
  .slick-arrow {
    display: none !important;
  }
}
.orderInfo {
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;
  // margin-bottom: 40px;
  padding-top: 20px;

  @media only screen and (max-width: 991px) {
    grid-template-columns: 1fr;
  }
  &__inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    place-items: center;

    @media only screen and (max-width: 767px) {
      grid-template-columns: 120px auto;
    }
  }
  &__image {
    width: 100%;
    aspect-ratio: 16/9;
    border-radius: 10px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 25px;
    padding: 14px 0;
    width: 100%;

    @media only screen and (max-width: 767px) {
      flex-direction: row;
      align-items: center;
      gap: 15px;
      padding: 0;
    }
    h4 {
      font-size: 21px;
    }
    p {
      font-size: 18px;
      line-height: normal;
      font-weight: 600;
      color: #333333;
      padding: 20px 0;
    }
    p {
      font-weight: 400;
      margin: 0;
    }

    .info-list {
      display: flex;
      align-items: center;
      gap: 15px;

      span {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
  }

  .btn_dark {
    svg {
      fill: red !important;
    }
    path {
      fill: none;
    }
  }
}

.PaymmentButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;

  button {
    padding: 10px 40px !important;
  }
}

.price-summary {
  padding: 10px;

  .price-summary-inner {
    width: 100%;
  }

  @media only screen and (max-width: 575px) {
    padding: 0;
  }
}
.price-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  gap: 16px;
  margin-bottom: 15px;
  &-amount {
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    display: block;
    text-align: right;
    font-weight: 600;
    margin-bottom: 5px;
    &:last-child {
      font-weight: 500;
    }
  }
  &.total {
    padding-top: 20px;
    border-top: 0.5px solid #e5e5e5;
    span {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: #000;
    }
  }
}

.price-item-label {
  // position: absolute;
  // right: 0px;
  // bottom: -35px;
  font-size: 12px;
  font-weight: 300;
  margin: 0;
  text-align: right;
}

.no-scroll {
  overflow: hidden;
}
.payment-container {
  padding: 10px 0 0;
  .sectionHeader {
    font-weight: 500;
    margin-bottom: 10px;
    @media only screen and (max-width: 575px) {
      font-size: 16px;
      line-height: 19px;
      font-weight: 500;
      display: block;
      width: 100%;
    }
    h3 {
      font-weight: 500;
      @media only screen and (max-width: 575px) {
        font-size: 16px;
        line-height: 19px;
        font-weight: 500;
        display: block;
        width: 100%;
      }
    }
    .orderInfo__content {
      .info {
        h4 {
          font-size: 14px;
          line-height: 16px;
          font-weight: 600;
          font-weight: 600;
          margin: 0 0 14px;
        }
      }
    }
  }
  .GreenContent {
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
  }
  .orderInfo__content p {
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    color: #333333;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .info-list {
    margin-top: 12px;
    span {
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
      color: #333333;
      display: block;
      margin: 0 0 4px;
      display: flex;
      align-content: center;
      gap: 6px;
      b {
        font-weight: 700;
      }
    }
  }
}

.payment-button {
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 5px;
  cursor: pointer;
}

.payment-button.active {
  background-color: black;
  color: white;
}

.subcategory-dropdown {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  position: absolute;
  background: #c7c7c7;
  border-radius: 10px;
  padding: 10px;
  z-index: 9999;
}

.subcategory-button {
  padding: 8px 16px;
  border: 1px solid #ddd;
  margin: 4px 0;
  cursor: pointer;
  border-radius: 5px;
  text-align: start;
  color: #333333;
}

.subcategory-button.active {
  background-color: #333333;
  color: white;
}

@media (max-width: 768px) {
  .orderInfo__inner {
    grid-template-columns: 1fr;
  }
}
