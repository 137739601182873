.contact-page {
  font-family: 'Arial', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(to bottom, #f9f9f9, #eaeaea);

  .contact-container {
    background: #fff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 600px;

    .contact-title {
      font-size: 2.5rem;
      color: #333;
      text-align: center;
      margin-bottom: 0.5rem;
      font-weight: bold;
    }

    .contact-subtitle {
      text-align: center;
      color: #555;
      margin-bottom: 2rem;
      font-size: 1.1rem;
    }

    .contact-info {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-bottom: 2rem;

      .contact-item {
        display: flex;
        align-items: center;
        gap: 1rem;
        background: #f5f5f5;
        padding: 0.75rem 1rem;
        border-radius: 5px;
        transition: all 0.3s ease;

        &:hover {
          background: #eaeaea;
        }

        .contact-icon {
          font-size: 1.5rem;
          color: #000000;
        }

        span,
        a {
          font-size: 1rem;
          color: #333;
          text-decoration: none;
        }

        a:hover {
          text-decoration: underline;
        }
      }
    }

    .contact-form {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .form-input,
      .form-textarea {
        width: 100%;
        padding: 0.75rem 1rem;
        font-size: 1rem;
        border: 1px solid #ddd;
        border-radius: 5px;
        outline: none;
        transition: border-color 0.3s ease;

        &:focus {
          border-color: #000000;
        }
      }

      .form-textarea {
        resize: none;
        height: 150px;
      }

      .form-submit {
        padding: 0.75rem 1.5rem;
        font-size: 1rem;
        font-weight: bold;
        color: #fff;
        background: #1c1c1c;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background: #393939;
        }
      }
    }
  }
}
