.admin-dashboard {
  display: flex;

  .sidebar {
    max-width: 350px;
    width: 100%;
    height: 100vh;
    padding-right: 20px;
    border-right: 1px solid #ebebeb;
    position: relative;

    transition: all 0.2s ease-in;

    .themeBrand {
      width: 150px;
      margin: 0 auto;
      display: block;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        cursor: pointer;
        transition: background-color 0.3s ease;
        padding-right: 10px;

        &.active {
          a {
            background-color: #333030;
            color: #fff;
          }
        }
        a {
          display: flex;
          align-items: center;
          gap: 10px;
          padding: 12px 20px;
          color: #333030;
          transition: 0.3s;
          text-decoration: none;
          border-radius: 0 30px 30px 0;
          margin-bottom: 5px;
          font-size: 16px;
          font-weight: 500;
          line-height: normal;
          &:hover {
            background-color: #333030;
            color: #fff;
          }
        }
      }
    }
  }
  .languageBtn {
    position: relative;
    cursor: pointer;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff !important;
    padding: 1px 3px;
    border-radius: 8px;
    &:hover {
      background-color: #000 !important;
      svg path {
        fill: #fff !important;
      }
    }
  }
  .top-bar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
    gap: 15px;

    .language {
      position: relative;
    }
  }

  .AdminMainContent {
    height: 100vh;
    width: calc(100% - 350px);
  }

  .users-table {
    padding-left: 20px;
    h2 {
      margin: 0 0 20px;
    }
    .table-responsive {
      height: calc(100vh - 116px);
      overflow: auto;
      padding-bottom: 40px;

      &::-webkit-scrollbar {
        width: 4px;
        
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #000000;
        border: 2px solid #555555;
      }
    }
    table {
      width: 100%;
      border: 1px solid #f3f3f3;
      border-radius: 8px;
      border-spacing: 0;
      min-width: 768px;
      thead {
        th {
          padding: 12px 15px;
          border-bottom: 1px solid #f3f3f3;
          background-color: #f3f3f3;
          text-align: left;
          white-space: nowrap;
          &:last-child {
            width: 100px;
            text-align: center;
          }
        }
      }
      tbody {
        tr {
          &:nth-child(even) {
            background-color: #f3f3f3;
          }
        }
        td {
          padding: 12px 15px;
          font-size: 14px;
          line-height: 26px;
          &:last-child {
            text-align: center;
          }
        }
      }
    }
  }
  .actionBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    position: relative;
    button {
      padding: 0;
      margin: 0;
      border: none;
      background-color: transparent;
      width: 20px;
      height: 20px;
      cursor: pointer;
      img {
        width: 100%;
      }
    }
    .infoText {
      display: none;
      font-size: 12px;
      line-height: normal;
      padding: 4px 12px;
      background-color: #333030;
      position: absolute;
      top: 100%;
      color: #f3f3f3;
      border-radius: 4px;
      z-index: 1;
    }
    .infoBtn:hover ~ .infoText {
      display: block;
    }
  }
  .toggle-btn {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 20px;
    margin-left: 200px;
    padding: 0px;
    :hover {
      background-color: none !important;
    }
  }

  .toggle-btn-close {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 20px;
    margin-left: 0px;
    padding: 0px;
    :hover {
      background-color: none !important;
    }
  }
  .product_action {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .common_product {
    margin: auto;
    cursor: pointer;
    font-size: 22px;
  }
  .not_verified_product {
    color: red;
  }
  .verified_product {
    color: green;
    // cursor: no-drop;
  }
  .verified_product_status {
    color: green;
    font-weight: 600;
  }

  .not_verified_product_status {
    color: red;
    font-weight: 600;
  }

  .active_product {
    color: green;
    font-weight: 600;
  }
  .inactive_product {
    color: red;
    font-weight: 600;
  }
  .sidebar-toggle {
    position: absolute;
    left: 0;
    top: 20px;
    background: #fff;
    height: 32px;
    width: 32px;
    z-index: 1111;
    font-size: 25px;
    color: #000;
    border: 2px solid #aaaaaa;
    border-left: none;
    border-radius: 0 8px 8px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
    cursor: pointer;
    @media only screen and (max-width: 991px) {
      display: flex;
    }
  }
}

@media (max-width: 991px){
  .admin-dashboard{
    // transform: translateX(-350px);

    .AdminMainContent{
      width: 100%;
    }
  }

  .sidebar-toggle{
    z-index: 999;
  }

  .admin-dashboard{
    &.sidebar-closed{
      .sidebar{
        left: -100%;
      }  
    }
    .sidebar {
      position: absolute;
      left: 0;
      background-color: #fff;
      z-index: 995;
    }

    .users-table{
      padding: 0 20px;
    }
  }
}