.chat-btn {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .chat-window {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 400px;
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
  }
  
  .chat-header {
    background-color: #007bff;
    color: white;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .chat-body {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
  }
  
  .chat-message {
    margin: 5px 0;
    padding: 5px 10px;
    border-radius: 5px;
  }
  
  .sent {
    background-color: #007bff;
    color: white;
    align-self: flex-end;
  }
  
  .received {
    background-color: #f1f1f1;
    align-self: flex-start;
  }
  
  .chat-footer {
    display: flex;
    padding: 10px;
    border-top: 1px solid #f1f1f1;
  }
  
  .chat-footer input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-right: 5px;
  }
  
  .chat-footer button {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
  }
  