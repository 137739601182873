.userDetails {
    &__head {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 24px;
    }
    &__card {
        border: 1px solid #ebebeb;
        padding: 10px;
        border-radius: 12px;
        &-head {
            padding: 65px 25px 0;
            margin-bottom: 40px;
            position: relative;
            display: flex;
            align-items: flex-end;
            gap: 20px;
            @media only screen and (max-width: 767px) {
                padding: 65px 12px 0;
                gap: 12px;
                flex-wrap: wrap;
            }
            &::before {
                height: 65px;
                width: 100%;
                border-radius: 8px;
                background: rgb(235,235,235);
background: linear-gradient(90deg, rgba(235,235,235,1) 0%, rgba(168,167,171,1) 100%);
                content: '';
                display: block;
                position: absolute;
                left: 0;
                top: 0;
            }
        }
        &-name {
            font-size: 22px;
            line-height: 30px;
            color: #000;
            margin: 0;
            font-weight: 800;
            display: block;
            @media only screen and (max-width: 767px) {
                font-size: 20px;
            line-height: 26px;
            }
        }
        &-mail {
            font-size: 16px;
            line-height: 22px;
            font-weight: 400;
            color: #000;
            display: block;
            margin: 6px 0 0;
            @media only screen and (max-width: 767px) {
                font-size: 14px;
            line-height: 20px;
            }
        }
    }
    &__img {
        margin-top: -30px;
        position: relative;
        height: 100px;
        width: 100px;
        border-radius: 12px;
        object-fit: cover;
        @media only screen and (max-width: 767px) {
            height: 85px;
        width: 85px;
        }
    }
    &__items {
        border: 1px solid #ebebeb;
        padding: 20px;
        border-radius: 8px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 24px;
        @media only screen and (max-width: 1024px) {
            grid-template-columns: 1fr 1fr;
        }
        @media only screen and (max-width: 767px) {
            grid-template-columns: 1fr;
            padding: 16px;
        }
    }
    &__item {
        h6 {
            font-size: 16px;
            line-height: 24px;
            color: #000;
            margin: 0;
            font-weight: 700;
            display: block; 
        }
        p {
            font-size: 16px;
            line-height: 22px;
            color: #000;
            margin: 4px 0 0;
            font-weight: 400;
            display: block; 
            @media only screen and (max-width: 767px) {
                font-size: 14px;
            line-height: 20px;
            }
        }
    }
}