.CreditDebitModalWrapper {
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow-y: auto;
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px #3333331b;
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #33333344;
    }
  }

  .modal-content {
    background: #fff;
    border-radius: 10px;
    width: 90%;
    max-width: 200px;
    max-height: 90vh; // Limit height to 90% of the viewport height
    padding: 20px;
    position: relative;
    text-align: center;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    overflow-y: auto; // Enable scrolling within the modal if content overflows

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px #3333331b;
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #33333344;
    }

    // Adjust dimensions for larger screens
    @media (min-width: 768px) {
      max-width: 500px;
      padding: 30px;
    }

    @media (min-width: 1024px) {
      max-width: 600px;
      padding: 40px;
    }
  }

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }

  .payment-header {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  .card-display {
    background: black;
    color: #fff;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
  }

  .card-logo {
    font-size: 1em;
    font-weight: bold;
    text-align: left;
  }

  .card-number {
    font-size: 1.2em;
    letter-spacing: 2px;
    margin: 10px 0;
  }

  .card-info {
    display: flex;
    justify-content: space-between;
  }

  .card-holder,
  .card-expiry {
    font-size: 0.8em;
  }

  .payment-form {
    display: flex;
    flex-direction: column;
    gap: 15px;

    label {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      input {
        width: 100%;
        margin-top: 5px;
        border: none;
        border-bottom: 1px solid #555;
      }
    }
  }

  .save-card {
    display: flex;
    align-items: center;
    font-size: 0.8em;
    color: #555;
    margin: 10px 0;
  }

  .total-amount {
    font-size: 1.1em;
    font-weight: bold;
    margin: 15px 0;
  }

  .pay-button {
    background-color: black;
    color: #fff;
    padding: 12px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    font-size: 1em;
    font-weight: bold;
    justify-content: center !important;
  }
}
