.slider_outer_container {
  margin: 0 -15px;
  padding-top: 20px;
  padding-bottom: 40px;

  .slick-dots {
    position: unset !important;

    li {
      width: fit-content !important;
      height: fit-content !important;

      button {
        width: 8px;
        height: 8px;
        background-color: #e7e7e7;
        transition: all 0.2s ease;
        border-radius: 10px;

        &::before {
          display: none;
        }
      }

      &.slick-active {
        button {
          width: 30px;
          background-color: #131313;
        }
      }
    }
  }
}

.categorySlider {
  height: 400px;
  padding: 0 15px;

  .categorySlider__img {
    height: 100%;
    width: 100%;

    overflow: hidden;
    border-radius: 35px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@media (max-width: 1080px) {
  .categorySlider {
    height: 388px;
  }
}

@media (max-width: 911px) {
  .categorySlider {
    height: 200px;

    .categorySlider__img{
        border-radius: 25px;
    }
  }
}
