.CartPageMainWrapper {
  padding-top: 20px;

  .search-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 9px;
    width: 100%;
    margin-top: 20px;

    input {
      border: none;
      background-color: transparent;
      font-size: 14px;
      width: calc(100% - 48px);
      outline: none;
    }

    .search-span-icon {
      font-size: 24px;
      color: #bdbdbd;
    }

    .filter_btn {
      padding: 0;
      border: none;
      background: transparent;

      svg,
      path {
        fill: #333333;
      }
    }
  }
}

.CartProductContainer {
  .ProductList {
    padding: 20px 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;

    .productItem {
      border-radius: 10px;
      border: 1px solid #cccccc;
      padding: 10px;

      .productItem__img {
        width: 100%;
        height: 200px;
        border-radius: 10px;
        overflow: hidden;

        img {
          object-fit: cover;
          height: 100%;
          width: 100%;
        }
      }

      .CartBtnContainer {
        display: flex;
        align-items: center;
        gap: 5px;
        padding-top: 10px;

        & > * {
          flex: 1;

          font-size: 12px;
        }
      }

      .titlePrice {
        padding-top: 20px;
        h4 {
          font-size: 22px;
          font-weight: 600;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .CartProductContainer {
    .ProductList {
      grid-template-columns: repeat(2, 1fr);

      .productItem {
        padding: 7px;
        .productItem__img {
          height: 115px;
        }

        .titlePrice {
          h4 {
            font-size: 18px;
          }
        }
        .CartBtnContainer {
          flex-direction: column;

          & > * {
            width: 100% !important;
            // font-size: 14px;
          }
        }
      }
    }
  }
}
