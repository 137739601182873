// OfferResponse.scss
.offer-response {
 display: flex;
 justify-content: center;
 align-items: center;
 min-height: 100vh;
 background-color: #f5f5f5;
 
 .loading-container {
   background: white;
   padding: 2rem;
   border-radius: 8px;
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
   
   .loading-message {
     text-align: center;
     
     .loading-title {
       font-size: 1.2rem;
       color: #333;
       margin-bottom: 1rem;
     }
     
     .loading-dots {
       .loading {
         font-size: 2rem;
         animation: loadingDots 1.5s infinite;
         
         &:nth-child(2) {
           animation-delay: 0.2s;
         }
         
         &:nth-child(3) {
           animation-delay: 0.4s;
         }
       }
     }
   }
 }
 
 .response-container {
   background: white;
   padding: 2rem;
   border-radius: 8px;
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
   text-align: center;
   
   .response-message {
     font-size: 1.2rem;
     color: #333;
     margin-bottom: 1rem;
   }
   
   .redirect-message {
     color: #666;
     font-size: 0.9rem;
   }
 }
}

@keyframes loadingDots {
 0%, 20% {
   transform: translateY(0);
   opacity: 0.3;
 }
 50% {
   transform: translateY(-5px);
   opacity: 1;
 }
 80%, 100% {
   transform: translateY(0);
   opacity: 0.3;
 }
}