.BlogMainWrapper {
  padding: 50px 0;

  h3 {
    font-size: 30px;
    text-align: center;
    font-weight: 600;
  }

  .BlogList {
    padding-top: 50px;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;

    .blog-post {
      padding: 20px;
      border-radius: 20px;
      border: 2px solid #a1a1a1;

      img {
        width: 100%;
        height: 250px;
        object-fit: cover;
        border-radius: 15px;
      }

      .blog-content {
        padding-top: 20px;

        h2.blog-title {
          font-size: 22px;
        }

        a.read-more-link {
          color: #131313;
          padding-top: 15px;
          display: block;
        }
      }
    }
  }
}

@media (max-width: 1080px) {
  .BlogMainWrapper {
    .BlogList {
      padding-top: 30px;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}


@media (max-width: 640px) {
  .BlogMainWrapper {
    padding: 20px 0;
    .BlogList {
      grid-template-columns: 1fr;
    }
  }
}
