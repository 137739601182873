.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-modal-content {
  background: white;
  border-radius: 10px;
  max-width: 560px;
  width: calc(100% - 32px);
  padding: 40px;
  box-sizing: border-box;
  text-align: left;
  @media (max-width: 991px) {
    padding: 30px;
  }
  h2 {
    margin: 0 0 25px;
    font-size: 22px;
    line-height: 32px;
    color: #333;
    font-weight: 700;
    @media (max-width: 991px) {
      font-size: 18px;
      line-height: 26px;
      margin: 0 0 20px;
    }
  }
  .modal-actions {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: start;
    button {
      min-width: 110px;
    }
  }
}
