.AboutMainContainer {
  max-width: 1200px;
  padding: 2rem 2rem;
  margin: auto;
  background: linear-gradient(180deg, #f8fafc 0%, #ffffff 100%);

  .container {
    h2 {
      font-size: 2.5rem;
      font-weight: 700;
      text-align: center;
      margin-bottom: 1rem;
      
      &:after {
        content: '';
        display: block;
        width: 6rem;
        height: 3px;
        background: #2563eb;
        margin: 1rem auto;
      }
    }

    h3 {
      font-size: 1.5rem;
      font-weight: 600;
      color: #1f2937;
      margin: 2rem 0 1rem;
    }

    p {
      line-height: 1.8;
      color: #4b5563;
      margin: 1rem 0;
    }

    .about-block {
      background: #eff6ff;
      border-radius: 1rem;
      padding: 2rem;
      margin: 2rem 0;

      ul {
        li {
          display: flex;
          gap: 1rem;
          margin: 1.5rem 0;

          &:before {
            content: '';
            flex-shrink: 0;
            width: 2rem;
            height: 2rem;
            background: #2563eb;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
          }

          strong {
            display: block;
            margin-bottom: 0.5rem;
          }
        }
      }
    }

    .benefits-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
      gap: 2rem;
      margin: 2rem 0;

      li {
        background: white;
        padding: 1.5rem;
        border-radius: 0.75rem;
        box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
        transition: transform 0.2s, box-shadow 0.2s;

        &:hover {
          transform: translateY(-2px);
          box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1);
        }

        h4 {
          font-size: 1.25rem;
          font-weight: 600;
          margin-bottom: 0.75rem;
        }
      }
    }

    .description {
      max-width: 800px;
      margin: 2rem auto;
      text-align: center;
      font-style: italic;
    }
  }
}

@media (max-width: 768px) {
  .AboutMainContainer {
    padding: 2rem 1rem;

    .container {
      h2 {
        font-size: 2rem;
      }

      .benefits-grid {
        grid-template-columns: 1fr;
      }
    }
  }
}