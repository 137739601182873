.ProfileMainWrapper {
  padding: 40px 0;

  .profile-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    img {
      width: 120px;
      aspect-ratio: 1/1;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .profileSectionBox {
    .profileSectionBoxGrid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      padding-top: 40px;
      gap: 30px;
    }
  }

  .profileSectionBox__inner {
    h3 {
      padding-bottom: 20px;
      font-family: "Poppins", serif !important;
      font-weight: 500;
      font-size: 16px;
    }
    .account-settings {
      border-radius: 25px;
      border: 1px solid #e2e2e2;
      padding: 25px 20px;
      min-height: 400px;

      display: grid;
      gap: 30px;

      .btnPart {
        text-align: center;
        display: flex;
        align-items: center;
        gap: 20px;
        justify-content: center;
      }
      .error-message {
        font-size: 14px;
        color: #ee4747;
      }
    }

    .account-detail {
      display: flex;
      align-items: center;
      padding-bottom: 15px;
      gap: 10px;
      height: fit-content;
      border-bottom: 1px solid #e2e2e2;
    }
    .account-detail.product {
      .product-image-container {
        width: 40%;
        height: 115px;
        overflow: hidden;
        border-radius: 15px;
        border: 1px solid #00000011;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .product-detail-container {
        display: flex;
        flex-direction: column;

        .product-title {
          padding-left: 0 !important;
          font-size: 18px;
          font-weight: 600;
        }

        .product-action {
          display: flex;
          align-items: center;
          gap: 10px;
          margin-top: 15px;

          button {
            min-width: 40px !important;
            min-height: 40px !important;

            &.EditIconBtn {
              background-color: #b0ffa941 !important;
              cursor: pointer;
            }
            &.DeleteIconBtn {
              background-color: #ffa9a941 !important;
              cursor: pointer;
            }
          }
        }
      }
    }
    .account-detail_inner {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      input {
        outline: none;
        flex: 1;
        border: none;
      }
    }
    .account-detail-user-password {
      display: flex;
      align-items: center;
      flex: 1;
      gap: 10px;

      input {
        flex: 1;
        border: none;
        background: transparent;
      }
    }

    &.PurchaseHistory {
      margin-top: 40px;
      .sold-products-container {
        min-height: 400px;

        .product-grid {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          gap: 20px;
        }
      }
    }

    &.ProductListing {
      .account-settings {
        height: 400px;
        overflow: auto;
        padding-right: 10px;

        &::-webkit-scrollbar {
          width: 4px;
          background-color: #f5f5f5;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #000000;
          border: 2px solid #555555;
        }
      }

      .AccountSettingsWrapper {
        border-radius: 25px;
        overflow: hidden;
      }
    }
  }

  .ServiceSectionBoxGrid {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    margin-top: 40px;

    & > * {
      flex: 1;
    }

    .profileSectionBox__inner {
      &.ServiceClient {
        .AccountSettings {
          display: grid;
          gap: 10px;

          .account-detail {
            padding-bottom: 10px;

            &:last-of-type {
              border-bottom: 0;
              padding-bottom: 0;
            }

            svg {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }

  .AccountSettings {
    padding: 15px;
    border-radius: 15px;
    border: 1px solid #e2e2e2;
  }

  .PartnersContainerGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    .PartnersContainer {
      padding: 15px;
      border-radius: 15px;
      border: 1px solid #e2e2e2;

      display: flex;
      align-items: center;
      justify-content: center;

      .PartnersImage {
        width: 100%;
        border-radius: 1;

        img {
          object-fit: contain;
        }
      }
    }
  }

  .SellerFlowWrapper {
    margin-top: 40px;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
   gap: 10px;

    h3 {
      font-family: "Poppins", serif !important;
      font-weight: 500;
      font-size: 16px;
    }
  }

  .faq_container {
    .faq_question {
      .faq_question-text h3 {
        display: flex;
        align-items: center;
        padding-bottom: 0;
        gap: 10px;

        svg {
          width: 20px;
          height: 20px;
        }
      }

      .icon-shape {
        width: 24px;
        height: 24px;

        &::before {
          height: 2px;
          width: 14px;
        }
        &::after {
          height: 14px;
          width: 2px;
        }
      }
    }
    &:last-of-type {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }

  .product-detail-header {
    .product-status {
      font-size: 12px;

      &.reject {
        color: #d83131;
        font-weight: 600;
      }
      &.pending {
        color: #d8b131;
        font-weight: 600;
      }
    }
  }

  .product_description {
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 200px;
    text-align: left;
  }
}

@media (max-width: 1080px) {
  .ProfileMainWrapper {
    .profileSectionBox {
      .profileSectionBoxGrid {
        grid-template-columns: 1fr;
      }
    }

    .profileSectionBox__inner.PurchaseHistory {
      .sold-products-container {
        .product-grid {
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }

    .ServiceSectionBoxGrid {
      flex-direction: column;

      & > * {
        flex: auto;
        width: 100%;
      }
    }
  }
}

@media (max-width: 768px) {
  .ProfileMainWrapper {
    .profileSectionBox__inner.PurchaseHistory {
      .sold-products-container {
        .product-grid {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }

    .PartnersContainerGrid {
      grid-template-columns: repeat(2, 1fr);
    }

    .btnPart {
      flex-direction: column;
      gap: 10px !important;

      & > * {
        width: 100%;
      }
    }
  }
}

.label-text{
  // margin-bottom: 20px;
  font-family: "Poppins", serif !important;
  font-weight: 500;
  font-size: 16px;
}