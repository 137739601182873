// ProductVerificationModal.scss

.product-verification-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.product-verification-modal {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 500px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  h3 {
    margin-bottom: 10px;
    font-size: 18px;
  }

  p {
    margin-bottom: 20px;
    color: #666;
    font-size: 14px;
  }

  .product-verification-modal-actions {
    display: flex;
    justify-content: center;
    gap: 20px;
  }

  .modal-accept-button {
    background-color: #4caf50;
    color: #fff;

    &:hover {
      background-color: #45a049;
    }
  }

  .modal-reject-button {
    background-color: #f44336;
    color: #fff;

    &:hover {
      background-color: #e53935;
    }
  }

  .modal-cancel-button {
    background-color: #9e9e9e;
    color: #fff;

    &:hover {
      background-color: #757575;
    }
  }
}
