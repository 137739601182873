.terms-container {
  // min-height: 100vh;
  padding: 2rem;
  background-color: #f8fafc;

  .terms-content {
  //   max-width: 1200px;
    margin: 0 auto;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }

  .terms-header {
    padding: 2.5rem 2rem;
    text-align: center;
    border-bottom: 1px solid #e5e7eb;
    background: linear-gradient(to right, #f8fafc, #ffffff);

    h1 {
      font-size: 2rem;
      font-weight: 700;
      color: #1f2937;
      margin: 0;
    }
  }

  .table-of-contents {
  //   position: sticky;
    top: 2rem;
    padding: 2rem;
    background-color: #ffffff;
    border-bottom: 1px solid #e5e7eb;

    h2 {
      font-size: 1.5rem;
      font-weight: 600;
      color: #1f2937;
      margin-bottom: 1.5rem;
    }

    nav {
      ol {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          margin-bottom: 0.75rem;

          button {
            width: 100%;
            text-align: left;
            padding: 0.75rem 1rem;
            background: none;
            border: none;
            border-radius: 6px;
            color: #4b5563;
            font-size: 1rem;
            cursor: pointer;
            transition: all 0.2s ease;

            &:hover {
              background-color: #f3f4f6;
              color: #2563eb;
            }

            &.active {
              background-color: #eff6ff;
              color: #2563eb;
              font-weight: 500;
            }
          }
        }
      }
    }
  }

  .terms-sections {
    padding: 2rem;

    .terms-section {
      margin-bottom: 1.5rem;
      border: 1px solid #e5e7eb;
      border-radius: 8px;
      overflow: hidden;

      .section-header {
        button {
          width: 100%;
          display: flex;
          align-items: center;
          padding: 1.25rem;
          background-color: #ffffff;
          border: none;
          cursor: pointer;
          transition: background-color 0.2s ease;

          &:hover {
            background-color: #f9fafb;
          }

          &.expanded {
            background-color: #f8fafc;
            border-bottom: 1px solid #e5e7eb;

            .toggle-icon {
              transform: rotate(180deg);
            }
          }

          .section-number {
            font-weight: 600;
            width: 28px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #000000;
            color: #ffffff;
            border-radius: 50%;
            font-size: 0.875rem;
            margin-right: 1rem;
          }

          h3 {
            flex-grow: 1;
            font-size: 1.125rem;
            font-weight: 500;
            color: #1f2937;
            text-align: left;
            margin: 0;
          }

          .toggle-icon {
            width: 20px;
            height: 20px;
            position: relative;
            transition: transform 0.3s ease;

            &::before,
            &::after {
              content: '';
              position: absolute;
              background-color: #6b7280;
              border-radius: 1px;
            }

            &::before {
              width: 2px;
              height: 12px;
              left: 9px;
              top: 4px;
            }

            &::after {
              width: 12px;
              height: 2px;
              left: 4px;
              top: 9px;
            }
          }
        }
      }

      .section-body {
        padding: 1.5rem;
        background-color: #ffffff;

        .section-content {
          color: #4b5563;
          line-height: 1.6;

          p {
            margin-bottom: 1.25rem;
          }

          .contact-box {
            background-color: #f8fafc;
            padding: 1.25rem;
            border-radius: 6px;
            border: 1px solid #e5e7eb;
            margin: 1.5rem 0;

            h4 {
              font-size: 1.125rem;
              font-weight: 600;
              color: #1f2937;
              margin-bottom: 1rem;
            }

            ul {
              list-style: none;
              padding: 0;
              margin: 0;

              li {
                margin-bottom: 0.5rem;
                padding-left: 1.25rem;
                position: relative;

                &::before {
                  content: '•';
                  position: absolute;
                  left: 0;
                  color: #3b82f6;
                }
              }
            }
          }

          .subsection {
            margin-bottom: 2rem;

            h4 {
              font-size: 1.125rem;
              font-weight: 600;
              color: #1f2937;
              margin-bottom: 1rem;
            }

            ul {
              list-style: none;
              padding-left: 1.5rem;
              margin: 1rem 0;

              li {
                margin-bottom: 0.75rem;
                position: relative;

                &::before {
                  content: '•';
                  position: absolute;
                  left: -1.25rem;
                  color: #3b82f6;
                }
              }
            }
          }
        }
      }
    }
  }

  .back-to-top {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    width: 48px;
    height: 48px;
    background-color: #3b82f6;
    color: #ffffff;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 50;

    &:hover {
      background-color: #2563eb;
      transform: translateY(-2px);
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }
}

// Responsive styles
@media (max-width: 768px) {
  .terms-container {
    padding: 1rem;

    .terms-content {
      border-radius: 8px;
    }

    .terms-header {
      padding: 1.5rem 1rem;

      h1 {
        font-size: 1.5rem;
      }
    }

    .table-of-contents,
    .terms-sections {
      padding: 1rem;
    }

    .back-to-top {
      bottom: 1.5rem;
      right: 1.5rem;
      width: 40px;
      height: 40px;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}

// Print styles
@media print {
  .terms-container {
    padding: 0;
    background-color: #ffffff;

    .terms-content {
      box-shadow: none;
    }

    .back-to-top {
      display: none;
    }

    .terms-section {
      break-inside: avoid;
    }

    .section-header button {
      background-color: #ffffff !important;
    }
  }
}