.AddProductMainWrapper {
  h3 {
    font-size: 22px;
    font-weight: 600;
    padding-top: 10px;
  }
}

.error-container{
  margin-top:-5px
}

.error-container-price{
  margin-top:-13px
}

.productForm {
  &__inner {
    display: grid;
    justify-content: space-between;
    gap: 20px;
    align-items: flex-start;
    grid-template-columns: repeat(2, 1fr);

    @media only screen and (max-width: 1199px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media only screen and (max-width: 991px) {
      grid-template-columns: repeat(1, 1fr);
    }
    @media only screen and (max-width: 768px) {
      grid-template-columns: 1fr;
      gap: 12px;
    }

    .add-product-description {
      grid-column-start: 1;
      grid-column-end: 3;
      @media only screen and (max-width: 991px) {
        grid-column-start: 1;
        grid-column-end: 3;
      }
      @media only screen and (max-width: 768px) {
        grid-column-start: 1;
        grid-column-end: 2;
      }
    }

    .form-group {
      margin: 0;
      input {
        border: 1px solid #e2e2e2;
        border-radius: 10px;
        font-size: 15px;
        line-height: 24px;
        padding: 12px 20px;
        width: 100%;
      }
      textarea {
        border: 1px solid #e2e2e2;
        border-radius: 10px;
        font-size: 15px;
        line-height: 24px;
        padding: 12px 20px;
        width: 100%;
        &:focus-visible,
        &:focus {
          box-shadow: unset;
          outline: none;
        }
      }
      &:nth-of-type(1) {
        z-index: 3;
      }
      &:nth-of-type(2) {
        z-index: 2;
      }
      &:nth-of-type(3) {
        z-index: 1;
      }
    }
    label {
      flex-direction: row;
      gap: 8px;
      align-items: center;
      input {
        width: auto;
      }
    }
  }
  .btnPart {
    margin-bottom: 1rem;
  }

  .productForm_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 34px;
    padding: 25px 0;

    .seller-add-payment {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    @media only screen and (max-width: 991px) {
      grid-template-columns: repeat(1, 1fr);
      gap: 0px;
    }
  }
}

.customSelect {
  font-size: 14px;
  line-height: 18px;
  color: #333333;
  font-weight: 400;
  position: relative;

  input {
    width: 0;
    height: 0;
    opacity: 0 !important;
  }
  & > div:nth-of-type(3) > div:last-child {
    width: 20px;
    height: 20px;
    flex: 20px 0 0;
  }
  &::before {
    content: "";
    position: absolute;
    background-image: url(../../../assets/svg/DownArrow.svg);
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 1;
  }
  > div {
    border: 1px solid #e2e2e2 !important;
    border-radius: 10px !important;
    min-height: 50px;
    &:focus-within {
      border: 1px solid #e2e2e2;
      outline: none;
      box-shadow: none;
    }
  }

  span,
  svg {
    display: none;
  }
  .MenuIcon {
    display: flex;
    align-items: center;
    gap: 8px;
    svg {
      display: block;
    }
  }
  & > div:nth-of-type(4) {
    z-index: 1111111;
    position: absolute;
  }
}

.customSelect__input {
  width: 0;
  height: 0;
  opacity: 0 !important;
}
/* Multi-select specific styles */
.customSelect__control {
  min-height: 50px !important;
  border-radius: 10px !important;
  border: 1px solid #e2e2e2 !important;
  font-size: 14px;
  position: relative;
  &:focus-within {
    border: 1px solid #e2e2e2;
    outline: none;
    box-shadow: none;
  }

  &::before {
    content: "";
    position: absolute;
    background-image: url(../../../assets/svg/DownArrow.svg);
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 1;
  }
  span,
  svg {
    display: none;
  }
  .MenuIcon {
    display: flex;
    align-items: center;
    gap: 8px;
    svg {
      display: block;
    }
  }
}
/* Remove custom arrow for multi-select */
.customSelect__control--is-multi::before {
  display: none;
}

/* Multi-value styling (tags/chips) */
.customSelect__multi-value {
  background-color: #e0f7fa;
  border-radius: 4px;
  margin: 4px;
  padding: 2px 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.customSelect__multi-value__label {
  color: #00796b;
  font-weight: 500;
  padding-right: 20px; /* Ensure space for the cross icon */
}

.customSelect__multi-value__remove {
  position: sticky;
  top: 50%;
  right: 1px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #00796b;

  /* Add cross icon */
  background: url("../../../assets/svg/Delete.svg") no-repeat center center;
  background-size: 12px;
  width: 12px;
  height: 12px;
  margin-bottom: -2px;

  &:hover {
    background-color: #00796b;
    color: #fff;
  }
}

.inputUpload {
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  background-color: #f2f2f2;
  padding: 20px 12px;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}

.imgPreviewGridWrapper{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.imgPreview {
  display: grid;
  gap: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
  &__inner {
    position: relative;
    border: 1px solid #e2e2e2;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 5px;
    justify-content: center;
    height: 80px;
    box-sizing: border-box;
    img {
      width: 100%;
      border-radius: 8px;
      height: 100%;
      max-height: 70px;
      object-fit: contain;
    }
    button {
      padding: 0;
      margin: 0;
      border: none;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      right: -10px;
      background-color: #fff;
      border-radius: 100%;
      cursor: pointer;
    }
    embed {
      width: 100%;
      height: 100%;
      border: none !important;
      border-radius: 8px;
    }
  }

  p {
    margin: 0;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 20px;
    font-size: 14px;
  }
}

.innerInputBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  padding: 7px;
  border: 1px solid #e2e2e2;
  border-radius: 10px;

  input {
    border: none;
    outline: none;
  }

  p {
    margin: 0;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    color: #333333;
  }

  .inputRight {
    display: flex;
    gap: 10px;
    align-items: center;
    input {
      padding: 6px;
      width: 90px;
      text-align: center;
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
      background-color: #f2f2f2;
      border: 1px solid #f5f5f5;
    }
    p {
      font-weight: 600;
    }
  }
}

.error {
  color: red;
  font-size: small;
  margin-top: 0;
  text-align: start;
}

.documentPreview {
  display: flex;
  align-items: center;

  &__inner {
    position: relative;
    display: flex;
    align-items: center;
  }

  &__thumbnail {
    position: relative;
    width: 80px; // Adjust width for small thumbnail
    height: 100px;
    border-radius: 8px;
    overflow: hidden;
    background-color: #f5f5f5; // Light background for the preview

    .documentPreview__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  // &__dismiss {
  //   position: absolute;
  //   top: -8px;
  //   right: -8px;
  //   background: white;
  //   border-radius: 50%;
  //   border: none;
  //   cursor: pointer;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  //   width: 20px;
  //   height: 20px;

  //   svg {
  //     width: 16px;
  //     height: 16px;
  //     color: #000;
  //   }

  //   &:hover {
  //     background-color: #e0e0e0;
  //   }
  // }

  &__name {
    margin-left: 16px; // Space between thumbnail and name
    font-size: 1rem;
    color: #333;
  }
}

.add-product-form-group {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

// Tooltip SCSS Styling
.rows {
  position: relative;

  .label {
    position: relative;
    display: inline-block;

    .tooltip {
      // position: static;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 7px;
      height: 14px;
      border-radius: 50%;
      background-color: black;
      color: white;
      cursor: pointer;
      font-size: 12px;
      font-weight: bold;
      margin-left: 5px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: black;
      }
    }

    .tooltip-text {
      visibility: hidden;
      position: absolute;
      z-index: 1;
      bottom: 125%;
      left: 50%;
      transform: translateX(-50%);
      background-color: black !important;
      color: white;
      text-align: center;
      border-radius: 6px;
      padding: 5px 10px;
      opacity: 0;
      transition: all 0.3s ease;
      white-space: nowrap;
      font-size: 12px;
    }

    &:hover {
      .tooltip-text {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

.add-product-container {
  padding-top: 40px;
}


@media (max-width: 768px) {
  .add-product-form-group {
    padding-top: 25px;
  }

  .innerInputBox{
    input{
      flex: 1;
      width: 20px;

    }
    .inputRight{
      flex: 1;
      input{
        width: 50%;
    }
  }
  }
}
