.popup {
  position: absolute;
  top: 35px;
  right: 0;
  background-color: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  z-index: 10;
  border-radius: 8px;
  padding: 15px;
  width: 300px;
  z-index: 9999;
}

.popup-content {
  display: flex;
  flex-direction: column;
  label {
    flex-direction: row;
    gap: 8px;
    input {
      width: auto;
    }
  }
}

.popup-content h4 {
  margin: 0;
  font-size: 14px;
}

.popup-content ul {
  list-style: none;
  padding: 0;
  margin: 20px 0 0;
}

.popup-content li {
  margin-bottom: 8px;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.close-icon {
  cursor: pointer;
  font-size: 14px;
  color: #888;
}

.close-icon:hover {
  color: #000;
}

.searchFilterBlock {
  position: relative;
  width: fit-content;
  max-width: 450px;
  width: 100%;
  .popup {
    top: 100%;
    transform: none;
    min-width: 180px;
    width: auto;
    left: auto;
    right: 0;
  }
}

input[type="checkbox"] {
  accent-color: #333333;
}

.popup-content {
  .filter-section {
    margin-bottom: 20px;

    ul {
      li {
        label {
          cursor: pointer;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .price-slider-container {
    padding: 0 15px;
  }

  .price-range-labels {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    color: black;
  }

  .apply-filters-button {
    width: 100%;
    padding: 10px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    justify-content: center;

    &:hover {
      background-color: black;
    }
  }
}

.filter-brand-icon {
  width: 30px;
  height: 30px;

  svg {
    width: 100%;
    height: 100%;
  }

  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
