.userActionContainer{
 display: flex;
 justify-content: center;
 align-items: center;
 gap:10px;
 cursor: pointer;
 color: black;
 &:hover {
  color : blue;
  border: 1px solid #ccc;
  transform: scale(1.02);
}
}

.hoverContent  {
 &:hover {
  color : blue;
 }
}