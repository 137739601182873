.loading {
  font-size: 16px; // Adjust the font size as needed
  font-weight: bold;
  display: inline-block;
  animation: bounce 0.6s infinite alternate;

  // Add a transition for a smoother effect
  transition: transform 0.3s;

  &:nth-child(1) {
    animation-delay: 0s;
  }

  &:nth-child(2) {
    animation-delay: 0.1s;
  }

  &:nth-child(3) {
    animation-delay: 0.2s;
  }

  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-10px); // Adjust the bounce height
    }
  }
}

// Loading dots
.loading-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  

  span {
    margin: 0 1px; // Space between dots
  }
}

.SignUpOuterWrapper {
  .container{

    .SignUpWrapper {
      padding-bottom: 40px;
      gap: 20px;
      width: 600px;
      
      .Login__form {
        padding-top: 40px;
      }

      .content-text{
        padding-top: 40px;
      }

      .separatorText{
        margin-top: 40px;
      }
    }
  }
}
