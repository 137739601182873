.buyer-chat-app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #f7f9fb;
  font-family: 'Arial', sans-serif;

  .heading {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    padding: 15px;
    background-color: #3f444a;
    color: #ffffff;
  }

  .chat-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-top: 1px solid #ddd;
    overflow: hidden;

    .messages {
      flex: 1;
      padding: 15px;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      gap: 10px;
      background-color: #f0f2f5;

      .message {
        max-width: 75%;
        padding: 12px 18px;
        border-radius: 15px;
        font-size: 1rem;
        line-height: 1.5;
        word-wrap: break-word;

        &.outgoing {
          align-self: flex-end;
          background-color: #1abc9c;
          color: white;
        }

        &.incoming {
          align-self: flex-start;
          background-color: #e0e0e0;
          color: #333;
        }
      }
    }

    .chat-input {
      display: flex;
      align-items: center;
      padding: 15px;
      background-color: #f7f9fb;
      border-top: 1px solid #ddd;

      .message-input {
        flex: 1;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 20px;
        outline: none;
        font-size: 1rem;
        margin-right: 10px;

        &:focus {
          border-color: #1abc9c;
        }
      }

      .send-btn {
        background-color: #1abc9c;
        color: white;
        padding: 10px 15px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #16a085;
        }
      }
    }
  }
}
