.auth-response {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f7f7f7;

  p {
    font-size: 1.2rem;
    color: #333;
    text-align: center;
    padding: 20px;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  }

  .loading {
    font-size: 1.5rem;
    color: #007bff;
  }
}
.auth-response {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px; // Add some padding around the response area
}

.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px; // Space between loading title and dots
  border: 1px solid #c0c0c0; // Light gray border
  border-radius: 10px;
  padding: 15px 20px; // Padding around the container
  background: #f9f9f9; // Light background for the container
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
  transition: all 0.3s ease; // Smooth transition for hover effects

  &:hover {
    background: #e8f0fe; // Change background on hover
  }
}

.loading-title {
  font-size: 18px; // Larger font size for visibility
  font-weight: bold;
  color: #333; // Darker text color for better readability
}

.loading-dots {
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-size: 24px; // Adjust dot size
    animation: bounce 0.6s infinite alternate;

    &:nth-child(1) {
      animation-delay: 0s;
    }

    &:nth-child(2) {
      animation-delay: 0.1s;
    }

    &:nth-child(3) {
      animation-delay: 0.2s;
    }
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px); // Adjust bounce height
  }
}

.response-message {
  font-size: 16px; // Font size for the response message
  color: #555; // Slightly lighter text color for feedback
  margin-top: 15px; // Space above the response message
}
