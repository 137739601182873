// Variables
$modal-z-index: 1000;
$border-radius-lg: 16px;
$border-radius-md: 12px;
$border-radius-sm: 8px;
$transition-base: all 0.3s ease;

// Mixins
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin absolute-full {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

// Animations
@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.view-product-modal {
  @include flex-center;
  @include absolute-full;
  position: fixed;
  z-index: $modal-z-index;

  // Modal Backdrop
  .modal-backdrop {
    @include absolute-full;
    background-color: rgba(0, 0, 0, 0.6);
    animation: modalFadeIn 0.3s ease-out;
  }

  // Modal Content
  .modal-content {
    position: relative;
    background: #ffffff;
    padding: 2.5rem;
    border-radius: $border-radius-lg;
    max-width: 500px;
    width: calc(100% - 20px);
    max-height: 90vh;
    margin: 10px;
    z-index: $modal-z-index + 1;
    animation: modalFadeIn 0.3s ease-out;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
      padding: 1.5rem;
    }

    @media (max-width: 480px) {
      padding: 1rem;
    }
  }

  // Close Button
  .close-button {
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
    @include flex-center;
    width: 24px;
    height: 24px;
    font-size: 1.5rem;
    color: #6c757d;
    background: rgba(255, 255, 255, 0.9);
    border: none;
    border-radius: 50%;
    cursor: pointer;
    transition: $transition-base;
    box-shadow: none;
    z-index: 2;

    &:hover {
      color: #212529;
      transform: scale(1.1);
      background: #ffffff;
    }
  }

  // Image Gallery Container
  .image-gallery-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    padding: 0;
    border-radius: $border-radius-md;

    .gallery-title {
      font-size: 1.5rem;
      color: #333;
      margin-bottom: 1rem;
      text-align: center;

      @media (max-width: 768px) {
        font-size: 1.25rem;
      }
    }
  }

  // Image Gallery Grid
  .image-gallery {
    display: grid;
    gap: 1rem;
    padding: 0.5rem;
    overflow-y: auto;
    max-height: calc(80vh - 140px);
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    height: auto;
    margin: 0;

    @media (max-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 480px) {
      grid-template-columns: 1fr;
    }

    // Scrollbar Styling
    &::-webkit-scrollbar {
      width: 8px;
      &-track {
        background: #f1f1f1;
        border-radius: $border-radius-sm;
      }
      &-thumb {
        background: #888;
        border-radius: $border-radius-sm;
        &:hover {
          background: #555;
        }
      }
    }
  }

  // Gallery Item
  .gallery-item {
    position: relative;
    aspect-ratio: 1;
    border-radius: $border-radius-md;
    overflow: auto;
    background-color: #f8f9fa;
    transition: $transition-base;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        height: 100px;
    width: 100%;
    overflow: hidden;
    .image-wrapper {
      height: 100%;
      width: 100%;
    }
    &:first-child {
      grid-column-start: 1;
    grid-column-end: 5;
    height: 400px;
 @media (max-width: 1024px) {
      grid-column-end: 4;
      height: 300px;
    }

    @media (max-width: 768px) {
      grid-column-end: 3
    }

    @media (max-width: 480px) {
     grid-column-end: 2;
     height: 250px;
    }
    }

    &:hover {
      transform: scale(1.02);
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: $transition-base;
    }

    // Download Button
    .download-button {
      position: absolute;
      bottom: 0.75rem;
      right: 0.75rem;
      @include flex-center;
      gap: 0.375rem;
      padding: 0.5rem 1rem;
      background: linear-gradient(135deg, black, #14161a);
      color: white;
      border: none;
      border-radius: $border-radius-sm;
      cursor: pointer;
      opacity: 1;
      transform: translateY(10px);
      transition: $transition-base;
      box-shadow: 0 4px 12px rgba(37, 99, 235, 0.3);

      &:hover {
        background: linear-gradient(135deg, #14161a, black);
        transform: translateY(-2px);
        box-shadow: 0 6px 16px rgba(37, 99, 235, 0.4);
      }

      span {
        font-size: 0.875rem;
        font-weight: 500;
      }
    }

    &:hover .download-button {
      opacity: 1;
      transform: translateY(0);
    }
    &::-webkit-scrollbar {
        width: 8px;
        &-track {
          background: #f1f1f1;
          border-radius: $border-radius-sm;
        }
        &-thumb {
          background: #888;
          border-radius: $border-radius-sm;
          &:hover {
            background: #555;
          }
        }
      }
  }
}