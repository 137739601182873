.product-page-container {
  h2 {
    text-align: center;
  }
  p {
    margin: 0;
  }
  .btnPart {
    text-align: right;
    margin: 10px 0;
  }
}
.filter-container {
  display: flex;
  align-items: flex-end;
  gap: 15px;
  .form-group {
    margin-bottom: 0;
  }
}
