.ErrorMainContainer {
  .container {
    min-height: calc(100vh - 465px);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 20px;

    a{
        color: #131313;
    }
  }
}
