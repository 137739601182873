.productDetail-container {
  flex: 1;
  max-width: 400px;
  margin-right: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: none;

  img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
}

.product-info {
  flex: 2;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  h1 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
  }

  .product-category {
    font-size: 16px;
    color: #777;
    margin-bottom: 10px;
  }

  .product-price {
    font-size: 20px;
    color: #e63946;
    font-weight: bold;
    margin-bottom: 15px;
  }

  .product-description {
    font-size: 14px;
    color: #555;
    margin-bottom: 20px;
    text-align: start;
  }
}

.productDetail {
  // max-width: 1140px;
  margin: 30px auto;
  &__inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 25px;
    @media (max-width: 991px) {
      grid-template-columns: 1fr;
    }
  }
  &__img {
    .productImg {
      position: relative;
      border-radius: 8px;
      border: 1px solid #ccc;
      margin-bottom: 15px;
      height: 500px;
      background: transparent;
      overflow: hidden;
      @media (max-width: 767px) {
        height: 400px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      .payment-status {
        position: absolute;
        top: 15px;
        left: 15px;
        font-size: 12px;
        padding: 5px 10px;
        border-radius: 35px;
        font-family: "poppins";
        text-transform: capitalize;
        z-index: 9999;

        &.pending {
          background-color: #ec3f3f;
          color: #fff;
        }
      }
    }
  }
  .btnPart {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-top: 25px;
    flex-wrap: wrap;
    button:last-child {
      background: transparent;
      color: #000;
      // width: 100%;
    }
    @media (max-width: 767px) {
      gap: 20px 12px;
      display: grid;
      grid-template-columns: 1fr;
      button:last-child {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }
  }
  &__content {
    h4 {
      margin: 0;
      font-size: 28px;
      line-height: normal;
      margin-bottom: 10px;
    }
    .price {
      label {
        font-weight: 500;
        font-size: 14px;
        line-height: normal;
        color: #ccc;
      }
      p {
        display: flex;
        align-items: center;
        gap: 16px;
        font-weight: 700;
        font-size: 26px;
        line-height: normal;
        span {
          font-size: 14px;
          font-weight: 400;
          text-decoration: line-through;
        }
      }
    }
  }
  .backBtn {
    margin-bottom: 15px;
  }
  .productImgSmall {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
    margin: 15px 0;
    img {
      width: 100%;
      height: 100%;
      max-height: 110px;
      border-radius: 4px;
      border: 1px solid transparent;
      transition: 0.3s;
      cursor: pointer;
      overflow: hidden;
      object-fit: contain;
      &:hover {
        border: 1px solid #ccc;
        transform: scale(1.02);
      }
    }
  }
}

.product-info-section {
  flex: 1.5;
  .product-title {
    text-transform: capitalize;
    font-size: 36px;
    margin: 0 0 30px;
    color: #333;
    @media (max-width: 991px) {
      margin: 0 0 20px;
      font-size: 30px;
    }
  }
  .product-category-brand {
    font-size: 16px;
    color: #2e2e2e;
    margin: 0 0 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 500;
    @media (max-width: 991px) {
      font-size: 14px;
      margin: 0 0 8px;
    }
    span {
      font-weight: 700;
      color: #333;
    }
  }

  .product-description {
    margin: 22px 0;
    h4 {
      font-size: 16px;
      margin: 0 0 5px;
    }
    p {
      font-size: 14px;
      color: #2e2e2e;
      font-weight: 400;
      text-align: start;
    }
  }

  .product-price-section {
    display: flex;
    align-items: center;
    gap: 15px;
    margin: 25px 0;
    .price-label {
      font-size: 14px;
      color: #ff6f61;
      font-weight: bold;
      margin: 0;
    }
    .product-price {
      font-size: 28px;
      font-weight: bold;
      color: #333;
      margin: 0;
      @media (max-width: 991px) {
        font-size: 24px;
      }
    }
  }
}

.rating_headline {
  font-weight: 900;
}

.product_rating {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  font-family: Arial, sans-serif;
  gap: 10px;
}

.product_rating p {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.stars {
  display: flex;
}

.star {
  font-size: 24px;
  margin-right: 4px;
  cursor: pointer;
  transition: transform 0.2s;
}

.star:hover {
  transform: scale(1.2);
  color: #ffd700;
}

.buy-now-btn.btn_dark {
  svg,
  path {
    fill: transparent !important;
  }
}

.productItem__img-btn {
  position: absolute;
  right: 15px;
  top: 15px;
  min-width: 35px !important;
  height: 35px !important;
  background-color: rgba(236, 63, 63, 0.2117647059) !important;
  border: 1px solid #e66363;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.productItem__img-btn svg {
  stroke: red;
}

// Add some responsiveness for smaller screens
@media (max-width: 767px) {
  .main-Wrapper {
    flex-direction: column;
    align-items: center;
    padding-bottom: 120px !important;
  }

  .productDetail-container {
    margin-right: 0;
    margin-bottom: 20px;
  }
}
