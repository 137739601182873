.document-cell {
  position: relative;

  .document-link {
    color: #3490dc; 
    text-decoration: underline;

    &:hover {
      color: #1d72b8; 
    }

    &:hover + .document-preview {
      display: block; 
    }
  }

  .document-preview {
    display: none;
    position: absolute;
    top: 20px;
    left: 60%; 
    width: 150px;
    height: 200px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    z-index: 10;
  }
}

.image-preview {
  position: relative;
  cursor: pointer;
  color: #3992ca;

  .view-button {
    display: inline-flex;
    align-items: center;
    border-radius: 6px;
    transition: all 0.3s ease;
    color: #3992ca;

    .icon {
      margin-right: 8px;
      width: 16px;
      height: 16px;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    span {
      font-size: 14px;
      color: #3992ca;
      text-decoration: underline;
    }
  }

  .hover-preview {
    display: none;
    position: absolute;
   top: 20%;
    right: 100%;
    transform: translateY(-50%);
    z-index: 100;
    padding: 6px;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    animation: previewFadeIn 0.2s ease-out;
    background: #fff;
    height: 156px;
    width: 156px;
        box-sizing: border-box;


    &::after {
          content: "";
    position: absolute;
    right: -8px;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid #ffffff;
    }

    img {
      width: 144px;
      height: 144px;
      object-fit: cover;
      border-radius: 4px;
      transition: transform 0.3s ease;

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  &:hover {
    .hover-preview {
      display: block;
    }

    .view-button {
      border-color: #dee2e6;
    }
  }
}

.calendar-icon {
  position: relative;
}

.popover {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  z-index: 10;
  white-space: nowrap;
}

.popover p {
  font-size: small;
  margin-top: 0px;
}

// .productActionContainer{
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   gap:10px;
//   cursor: pointer;
//   color: red;
//  }