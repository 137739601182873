.notification-page {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  max-width: 100%;
  margin: auto;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin: 0px 40px;

  .notification {
    padding: 15px;
    border-radius: 5px;
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    gap: 5px;

    &.custom-code {
      background-color: #f3f4f6;
      border-left: 5px solid #9ca3af;

      button {
        background: none;
        border: none;
        color: #1f2937;
        cursor: pointer;
      }
    }

    &.data-export {
      background-color: #e0e7ff;
      border-left: 5px solid #6366f1;

      button {
        background: none;
        border: none;
        color: #3b82f6;
        cursor: pointer;
      }
    }

    &.no-credits {
      background-color: #fef3c7;
      border-left: 5px solid #f59e0b;

      button {
        background: none;
        border: none;
        color: #d97706;
        cursor: pointer;
      }
    }

    &.password-warning {
      background-color: #fde68a;
      border-left: 5px solid #fbbf24;
    }

    &.success-upload {
      background-color: #d1fae5;
      border-left: 5px solid #10b981;
    }

    &.new-update {
      background-color: #dbf3fe;
      border-left: 5px solid #3b82f6;

      button {
        background: none;
        border: none;
        color: #3b82f6;
        cursor: pointer;
      }
    }

    &.did-you-know {
      background-color: #e0f2fe;
      border-left: 5px solid #0ea5e9;
    }

    &.submission-error {
      background-color: #fee2e2;
      border-left: 5px solid #ef4444;
      span {
        font-size: 0.9em;
      }
    }

    &.crash {
      background-color: #fef2f2;
      border-left: 5px solid #f87171;

      button {
        background: none;
        border: none;
        color: #ef4444;
        cursor: pointer;
      }
    }
    .created-at {
      display: block;
      font-size: 0.7rem;
      color: #555;
      margin-top: 4px;
    }
  }
}

/* ButtonStyles.css */
.update-time-slot-btn {
  margin-top: 10px;
  padding: 6px 8px;
  border-radius: 8px;
  border: 1px solid black;
  background-color: white;
  color: black;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  outline: none;
 font-size: 12px;
}

.update-time-slot-btn:hover {
  background-color: black; /* Darken background on hover */
  color: white;
}
