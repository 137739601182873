// InvoiceBreakdown.scss
.invoice-breakdown {
  font-family: Arial, sans-serif;
  max-width: auto;
  margin: auto;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  background: #fff;
  margin: 5px 0px;
  min-height: 272px;

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .label {
      font-size: 14px;
      position: relative;

      .tooltip {
        margin-left: 5px;
        cursor: pointer;
        background: black;
        border-radius: 50%;
        width: 7px;
        height: 14px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: bold;
        color: white;

        &:hover + .tooltip-text {
          display: block;
        }
      }

      .tooltip-text {
        display: none;
        position: absolute;
        top: 20px;
        right: 0;
        background: #000;
        color: #fff;
        font-size: 12px;
        padding: 3px 7px;
        border-radius: 4px;
        white-space: nowrap;
        z-index: 9999;
      }
    }

    .value {
      font-size: 14px;

      &.negative {
        color: red;
      }
    }
  }

  .total-row {
    font-weight: bold;
    border-top: 1px solid #ddd;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;

    .value {
      font-size: 16px;
    }
  }
}
