// SellerFlow.scss

.seller-flow-container {
  // display: flex;
  flex-direction: column;
  align-items: center;
  // padding: 20px;
  font-family: Arial, sans-serif;

  .open-modal-button {
    background-color: black;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: black;
    }
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .seller-flow-modal-content {
    max-height: 90vh;
    background: #fff;
    width: 90%;
    max-width: 520px;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: auto;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar {
      width: 4px;
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #000000;
      border: 2px solid #555555;
    }

    @media only screen and (max-width: 767px) {
      max-height: 90vh;
    }

    .modal-title {
      font-size: 24px;
      margin-bottom: 20px;
      text-align: center;
    }

    .close-modal-button {
      position: absolute;
      top: 10px;
      right: 10px;
      background: transparent;
      border: none;
      font-size: 18px;
      cursor: pointer;
    }

    .flow-structure {
      display: flex;
      flex-direction: column;
      gap: 10px;

      &.horizontal {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
      }

      .flow-step {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        flex: 1;

        .flow-icon {
          background: #f0f0f0;
          border-radius: 50%;
          padding: 10px;
          margin-bottom: 10px;
        }

        .flow-label {
          font-size: 12.5px;
          color: #555;
          // margin-bottom: 10px;
        }

        .flow-description {
          font-size: 14px;
          color: #555;
          margin-bottom: 10px;
        }

        .sub-steps {
          display: flex;
          flex-direction: row;
          gap: 10px;
          position: relative;

          .sub-step {
            display: flex;
            flex-direction: column;
            align-items: center;

            .sub-step-icon {
              background: #f8f8f8;
              border-radius: 50%;
              padding: 10px;
              margin-bottom: 5px;
            }

            .sub-step-label {
              font-size: 12px;
              color: #666;
              text-wrap: nowrap;
            }
          }
          .sub-step-arrow {
            position: absolute;
            top: 50%;
            bottom: 50%;
            padding-left: 40px;
          }
        }
      }

      .flow-arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        color: black;
        // padding-top: 46px;
        @media only screen and (max-width: 767px) {
          justify-content: center;
          align-items: center;
          padding-top: 0px;
        }
      }
    }
  }
}
