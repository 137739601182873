.row-payment {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  position: relative;
  gap: 8px;
  margin-bottom: 12px;

  .label-payment {
    font-size: 16px;
    color: #202020;
  }

  .tooltip-payment {
    cursor: pointer;
    background: #eee;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    position: absolute;
    left: 8rem;

    &:hover + .tooltip-text-payment {
      visibility: visible;
      opacity: 1;
    }
  }

  .tooltip-text-payment {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    background: #333;
    color: white;
    padding: 8px 12px;
    border-radius: 4px;
    font-size: 12px;
    width: max-content;
    max-width: 200px;
    top: -3.5rem;
    left: 8.5rem;
    transform: translateX(-50%);
    transition: all 0.2s ease;
    z-index: 10;

    &:after {
      content: "";
      position: absolute;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
      border-width: 5px 5px 0;
      border-style: solid;
      border-color: #333 transparent transparent;
    }
  }

  .price-item-amount {
    margin-left: auto;
    font-weight: 600;
    font-size: 16px;
  }
}
