@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  margin: 0;
  font-family: "Montserrat", sans-serif !important;
}
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
input,
button,
textarea,
select {
  font: inherit;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
p {
  text-wrap: pretty;
}
// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//   text-wrap: balance;
// }

#root,
#__next {
  isolation: isolate;
}

button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.btn_dark {
  background-color: #333333 !important;
  color: #fff !important;

  svg,
  path {
    fill: #fff !important;
  }
}

.IconBtn {
  padding: 0 !important;
  width: 24px !important;
  min-width: 24px !important;
  height: 24px !important;
  background: transparent !important;
}

.container {
  margin: 0 auto;
  max-width: 1650px;
  padding: 0 20px;
  width: 100%;
}

.mobile_visible {
  display: none;
}

.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  gap: 10px;
  backdrop-filter: blur(2px);

  p {
    color: #fff;
  }
}

.loader {
  width: 50px;
  height: 50px;
  border: 3px solid black; // Light outer ring
  border-top: 3px solid #ffffff; // White top ring
  border-radius: 50%;
  animation: spin 1s linear infinite; // Rotating animation
}

.confirmationModel__inner {
  background: #fff;
  border-radius: 10px;
  width: 90%;
  max-width: 200px;
  max-height: 90vh;
  padding: 20px;
  position: relative;
  text-align: center;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  overflow-y: auto;

  display: flex;
  flex-direction: column;

  img {
    width: 50%;
    margin: 0 auto;
  }

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #3333331b;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #33333344;
  }

  @media (min-width: 768px) {
    max-width: 500px;
    padding: 30px;
  }

  @media (min-width: 1024px) {
    max-width: 600px;
    padding: 40px;
  }

  button.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

// Keyframes for rotation animation
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 1440px) {
  .container {
    max-width: 1280px;
  }
}

@media (max-width: 768px) {
  .desktop_visible {
    display: none !important;
  }

  .mobile_visible {
    display: block;
  }
}

input:-webkit-autofill {
  background-color: white !important; /* Ensures the background remains white */
  -webkit-box-shadow: 0 0 0px 1000px white inset !important; /* Prevents yellow/blue background */
  color: inherit !important; /* Ensures the text color matches the rest of your design */
}

input:-webkit-autofill:focus {
  background-color: white !important; /* Keeps the background white on focus */
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  color: inherit !important;
}