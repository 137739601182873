.productActionContainer{
 display: flex;
 justify-content: end;
 align-items: center;
 gap:10px;
 cursor: pointer;
}

.productActionContainer_Delete{
 color : rgb(79, 78, 78)
}