.ResetPassword__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;

  .themeBrand {
    display: block;
    width: 250px;
    margin: 0 auto;
  }

  .ResetPassword__form {
    padding: 35px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 20px;
    max-width: 300px;
    width: 100%;

    .form-group {
      display: flex;
      align-items: center;
      gap: 15px;
      border-bottom: 1px solid #333333;
      padding-bottom: 15px;
      width: 100%;

      input {
        background-color: transparent !important;
        border: 0;
        flex: 1;

        &:-internal-autofill-selected {
          background-color: transparent !important;
        }
      }
      .input-with-icon {
        border: none !important;
        outline: none !important;
      }
    }
  }

  .formGroupWrapper{
    width: 100%;

    .error-message{
      text-align: center;
      color: red;
    }
  }
}

@media (max-width: 400px) {
  .container:has(.ResetPassword__inner) {
    padding: 0 !important;
  }

  // svg,
  // .EyeIcon {
  //   flex: 1;
  //   width: fit-content !important;
  // }
}
