.ChatMainContainer {
  height: calc(100vh - 130px);

  .noDataBlock {
    display: grid;
    gap: 20px;

    button {
      width: fit-content !important;
      margin: 0 auto;
    }
  }
}

.blurred {
  filter: blur(1px);
  position: relative;
  overflow: hidden !important;
}

.sold-out-overlay {
  position: absolute;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(189, 185, 185, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.sold-out-text {
  color: rgb(208, 17, 17);
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}

.ChatAppContainer {
  --sidebar-width: 450px;
  --chat-gap: 0;

  display: flex;
  align-items: flex-start;
  gap: var(--chat-gap);
  width: 100%;
}

.ChatSidebar {
  max-width: var(--sidebar-width);
  width: 100%;
  min-height: calc(100vh - 130px);
  border-right: 1px solid #e4e4e4;
  border-left: 1px solid #e4e4e4;
  padding: 20px;
  height: calc(100vh - 130px);
  &::-webkit-scrollbar {
    width: 4px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #000000;
    border: 2px solid #555555;
  }

  .SideBarHeader {
    display: flex;
    gap: 15px;

    & > * {
      flex: 1;
    }

    button {
      font-size: 12px !important;
    }
  }

  .UserChatList {
    padding-top: 30px;
    padding-bottom: 100px;

    .search-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      background-color: #f5f5f5;
      padding: 13px 10px;
      border-radius: 9px;
      width: 100%;

      input {
        border: none;
        background-color: transparent;
        font-size: 14px;
        width: calc(100% - 48px);
        outline: none;
      }

      .search-span-icon {
        font-size: 24px;
        color: #bdbdbd;
      }

      .filter_btn {
        padding: 0;
        border: none;
        background: transparent;

        svg,
        path {
          fill: #333333;
        }
      }
    }

    ul {
      padding: 0;
      list-style: none;
      padding-top: 20px;
      display: grid;
      gap: 10px;

      li.userItem {
        display: flex;
        gap: 12px;
        align-items: center;
        padding: 10px;
        cursor: pointer;
        transition: all 0.6s ease;
        border-radius: 15px;
        position: relative;
        margin-bottom: 5px;

        &:hover {
          background-color: #f5f5f5;
        }

        &::after {
          position: absolute;
          content: "";
          background: #e6e6e6;
          width: 100%;
          height: 1px;
          bottom: -5px;
          left: 0;
        }

        &.active {
          background-color: #c7c7c7;
        }

        .userItem__infoContainer {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;

          p {
            color: #333333;
            font-size: 12px;
          }
        }

        .userItem__img {
          width: 60px;
          aspect-ratio: 1/1;
          object-fit: cover;
          border-radius: 50%;
        }

        .userItem__icon {
          width: 60px;
          aspect-ratio: 1/1;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #aaaaaa13;
          border-radius: 50%;
        }

        .userItem__info {
          flex: 1;
          .userItem__name {
            font-size: 16px;
            font-weight: 600;
          }
          .userItem__text {
            margin-top: 2px;
          }
        }
      }
    }
  }

  .ErrorBlank,
  .LoadingStat {
    padding: 20px 0;
    font-weight: 600;
  }

  .userItem__chatInfoContainer {
    padding-top: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    .userItem__name {
      color: #2e2e2e;
      font-weight: 500 !important;
    }

    .userItem__msgCount {
      background-color: #333333;
      font-size: 10px;
      color: #fff;
      min-width: 24px;
      aspect-ratio: 1/1;
      padding: 4px;

      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }
  }
}

.ChatBody {
  max-width: calc(100% - (var(--sidebar-width) + var(--chat-gap)));
  width: 100%;
  height: calc(100vh - 111px);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .ChatInnerHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #aaaaaa;
    padding-bottom: 10px;

    .chat-body-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 15px;
      width: 100%;

      .chat-product-img {
        width: 60px;
        aspect-ratio: 1/1;
        object-fit: cover;
        border-radius: 50%;
      }

      .ChatBodyUserInfo {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
      }
    }

    .chat-body-header__text {
      font-size: 14px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 200px;
    }
  }

  .closeBtn {
    display: none;
    cursor: pointer;
  }

  .messages {
    overflow: auto;
    padding: 0 20px;
    display: grid;
    flex: 1;
    gap: 20px;
    position: relative;

    .loader-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      background: #0000000f;
      backdrop-filter: blur(6px);
    }

    &::-webkit-scrollbar {
      width: 4px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #000000;
      border: 2px solid #555555;
    }

    .message {
      display: flex;
      flex-direction: column;

      &.sender {
        align-items: flex-end;
        text-align: right;
        .content {
          border-radius: 20px 0px 20px 20px;
        }
        .time {
          padding-right: 5px;
        }
      }
      &.receiver {
        align-items: flex-start;
        text-align: left;
        .content {
          border-radius: 0px 20px 20px 20px;
        }
        .time {
          padding-left: 5px;
        }
      }

      .content {
        background-color: #eeeeee;
        padding: 20px;
        width: fit-content;
      }

      .time {
        color: #2e2e2e;
        font-size: 14px;
      }
    }
  }

  form.ChatInputContainer {
    display: flex;
    align-items: center;
    border-radius: 17px;
    background-color: #f1f1f1;
    padding-right: 30px;

    input {
      border: none;
      background: transparent;
      flex: 1;
      outline: none;
      padding: 20px 10px 20px 30px;
    }

    .ChatInputBtnContainer {
      display: flex;
      align-items: center;
      gap: 40px;

      button {
        background-color: transparent;
        border: none;
        cursor: pointer;
      }
    }

    .addBtn {
      cursor: pointer;

      label {
        cursor: pointer;
        margin-bottom: 0;
      }

      input {
        display: none;
      }
    }
  }
}

@keyframes fadeHighlight {
  0% {
    background-color: #ffeeba;
    border: 1px dashed #ffcc00;
  }
  100% {
    background-color: transparent;
    border: none;
  }
}

.userItem {
  transition: background-color 0.5s ease-in-out, border 0.5s ease-in-out;

  &.no-message {
    animation: fadeHighlight 5s ease-in-out forwards;
    animation-iteration-count: 1;
    border: 1px dashed #ffcc00;
    background-color: #ffeeba;

    .userItem__info {
      color: #999;
    }
  }
}

@media (max-width: 1080px) {
  .ChatAppContainer {
    --sidebar-width: 350px;
  }

  .ChatSidebar,
  .ChatBody {
    padding: 10px;
  }
}

@media (max-width: 768px) {
  .ChatMainContainer {
    overflow: hidden !important;
    position: relative;
  }

  .ChatSidebar {
    max-width: 100%;
    border: none;
    padding-left: 0;
    padding-right: 0;
  }

  .ChatBody {
    position: absolute;
    z-index: 999;
    height: 100%;
    width: 100%;
    max-width: 100%;
    top: 0;
    left: 0;
    background-color: #fff;
    padding: 10px 20px;
    clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
    transition: all 0.2s ease;

    &.open {
      clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);
    }

    .ChatInnerHeader {
      justify-content: flex-start;
      gap: 15px;
    }

    .closeBtn {
      display: block;
    }
  }
}
