.faq_main_container {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  padding: 100px 0;
  min-height: calc(100vh - 365px);

  h2 {
    font-size: 35px;
    font-weight: 700;
    padding-bottom: 25px;
  }
}

.faq_container {
  border-bottom: 2px solid #ddd;
}

.faq_question {
  font-size: 1.2rem;
  font-weight: 600;
  padding: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.answercont {
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.answer {
  padding: 0 10px 20px;
  line-height: 1.5rem;
}

.faq_question-text h3 {
  font-size: 18px;
}

.icon {
  display: flex;
  align-items: center;
}

.icon-shape {
  position: relative;
  width: 50px;
  height: 50px;
}

.icon-shape::before {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 4px;
  background: #333;
  border-radius: 3px;
  transition: all 0.5s ease;
}

.icon-shape::after {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 4px;
  height: 30px;
  background: #333;
  border-radius: 3px;
  transition: all 0.5s ease;
}

.icon-shape.active::before {
  transform: translate(-50%, -50%) rotate(180deg);
  transition: all 0.5s ease;
}

.icon-shape.active::after {
  transform: translate(-50%, -50%) rotate(90deg);
  transition: all 0.5s ease;
}

@media (max-width: 768px) {
  .faq_main_container {
    padding: 50px 0;
  }
}
