.no-image-placeholder {
  max-width: 30%; /* Default size for larger screens */
  height: auto;
  display: block;
  margin: 0 auto;

  /* Add responsiveness */
  @media (max-width: 1024px) {
    /* For tablets and smaller screens */
    max-width: 40%;
  }

  @media (max-width: 768px) {
    /* For small tablets and larger phones */
    max-width: 50%;
  }

  @media (max-width: 480px) {
    /* For mobile devices */
    max-width: 70%;
  }
}

.noDataBlock {
  text-align: center;
  padding: 20px;

  /* Add responsiveness */
  @media (max-width: 768px) {
    padding: 15px;
  }

  @media (max-width: 480px) {
    padding: 10px;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 15px;

  .pagination-btn {
    padding: 8px 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: white;
    cursor: pointer;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &:hover:not(:disabled) {
      background: #f5f5f5;
    }
  }

  .pagination-info {
    font-size: 14px;
  }
}

.SearchResultsWrapper {
  padding-bottom: 50px;

  .product-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    padding-top: 20px;
  }

  .productItem__img {
    width: 100%;
    height: 200px;
    border-radius: 20px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .productItem {
    padding: 12px;
    border: 1px solid #cfcfcf;
    border-radius: 20px;
    position: relative;

    .productItem__img-btn {
      position: absolute;
      right: 20px;
      top: 20px;
    }
  }

  .titlePrice {
    padding: 20px 0;
  }

  .ProductTitle {
    font-size: 22px;
    font-weight: 600;
  }

  .ProductDescription {
    padding-top: 10px;
  }

  button.btn_dark.view_details_btn {
    width: 100%;
  }

  .search-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 9px;
    width: 100%;
    margin-top: 20px;

    input {
      border: none;
      background-color: transparent;
      font-size: 14px;
      width: calc(100% - 48px);
      outline: none;
    }

    .search-span-icon {
      font-size: 24px;
      color: #bdbdbd;
    }

    .filter_btn {
      padding: 0;
      border: none;
      background: transparent;

      svg,
      path {
        fill: #333333;
      }
    }
  }
}

@media (max-width: 991px) {
  .SearchResultsWrapper {
    .product-list {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media (max-width: 640px) {
  .SearchResultsWrapper {
    .product-list {
      grid-template-columns: 1fr;
    }
  }
}
