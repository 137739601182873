.ImageUpload {
  width: 100%;
  padding: 20px 0;

  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    margin-bottom: 16px;

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 480px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .relative {
    position: relative;
  }

  .aspect-square {
    aspect-ratio: 1 / 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .border {
    border: 1px solid #e5e7eb;
  }

  .border-2 {
    border-width: 2px;
  }

  .border-dashed {
    border-style: dashed;
  }

  .rounded-lg {
    border-radius: 8px;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .imgPreview__inner {
    position: relative;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .absolute {
      position: absolute;
    }

    button {
      &.absolute {
        border: none;
        cursor: pointer;
        transition: all 0.2s ease;

        &.top-2 {
          top: 8px;
        }

        &.right-2 {
          right: 8px;
        }

        &.bottom-0 {
          bottom: 0;
        }

        &.left-0 {
          left: 0;
        }

        &.right-0 {
          right: 0;
        }
      }

      &.bg-black\/50 {
        background-color: rgba(0, 0, 0, 0.5);
      }

      &.hover\:bg-black\/70:hover {
        background-color: rgba(0, 0, 0, 0.7);
      }

      &.rounded-full {
        border-radius: 9999px;
      }

      &.p-1 {
        padding: 4px;
      }

      &.text-white {
        color: white;
      }

      &.text-xs {
        font-size: 12px;
      }

      &.py-1 {
        padding-top: 4px;
        padding-bottom: 4px;
      }
    }

    .bg-cyan-400 {
      background-color: #22d3ee;
    }
  }

  label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
    min-height: 150px;
    background-color: #f9fafb;

    &:hover {
      border-color: #9ca3af;
      background-color: #f3f4f6;
    }

    .h-6 {
      height: 24px;
    }

    .w-6 {
      width: 24px;
    }

    .mb-2 {
      margin-bottom: 8px;
    }

    .text-sm {
      font-size: 14px;
    }

    .text-center {
      text-align: center;
    }
  }

  div[class*="placeholder"] {
    background-color: #f9fafb;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 150px;

    .text-gray-300 {
      color: #d1d5db;
    }
  }

  .hidden {
    display: none;
  }

  .text-red-500 {
    color: #ef4444;
  }

  .mt-2 {
    margin-top: 8px;
  }
}

.h-4 {
  height: 16px;
}

.w-4 {
  width: 16px;
}

.h-6 {
  height: 24px;
}

.w-6 {
  width: 24px;
}

.text-white {
  color: white;
}

.text-gray-300 {
  color: #d1d5db;
}

.ImageUploadGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;

  .singleAngleLabel {
    font-size: 12px;
    text-align: center;
  }

  .imageUploadError {
    margin-top: 10px;
    font-size: 12px;
    text-align: left;
    color: #ef4444;
  }

  .singleImageAngleImgPreview {
    width: 100%;
    aspect-ratio: 1/1;
    overflow: hidden;
    position: relative;
    border-radius: 8px;
    border: 2px solid #9ca3af55;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .singleImageAngleClosebutton {
    position: absolute;
    top: 5px;
    right: 5px;
    border-radius: 50px;
    width: 25px;
    height: 25px;
    background-color: #000;
    border: none;
    cursor: pointer;
  }
}

@media (max-width: 768px) {
  .ImageUpload {
    padding: 10px 0;
  }
  .ImageUploadGrid {
    grid-template-columns: repeat(2, 1fr);

    .singleImageAngleImgPreview,
    .singleImageAngleImgPreviewSkeleton label {
      width: 100% !important;
    }
  }
}
