.CatOuterContainer {
  overflow-y: auto;
  padding-bottom: 20px;

  &::-webkit-scrollbar {
    width: 0;
  }

  // &:not(:last-of-type) {
  //   padding-bottom: 25px;
  // }

  h6 {
    font-size: 18px;
    // padding-bottom: 10px;
  }

  .CatBtnContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    width: fit-content;
    padding: 0 4px;

    button {
      width: fit-content !important;

      svg {
        width: 24px;
        height: 24px;
        max-width: 24px;
      }
    }

    button.activeCategory,
    button.activeSubCategory {
      background-color: #333333 !important;
      color: #fff;

      svg,
      path {
        fill: #fff !important;
      }
    }
  }
}

.CatBtnOuterContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  padding-bottom: 10px;
}

.ProductCardGridContainer {
  padding: 20px 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.FilterDropdownContainer {
  padding: 15px;
}

.FilterHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .CloseIconBtn {
    svg {
      width: 30px;
      height: 30px;
    }
  }
}

.FilterDropdownMainWrapper {
  .MuiPaper-root.MuiPaper-elevation {
    box-shadow: none !important;
    border: 1px solid #dddddd;
    border-radius: 15px;
  }
}

.SelectedFilterOuterContainer {
  padding: 12px;
  background-color: #f5f5f5ef;
  border-radius: 10px;

  .SelectedFilterContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    padding-top: 10px;
  }

  h3 {
    font-weight: 400;
  }

  .show-applied-filter {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #000;
    padding: 2px 8px;
    color: #fff;

    svg {
      cursor: pointer;
    }
  }
}

@media (max-width: 1440px) {
  .ProductCardGridContainer {
    padding: 20px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
}

@media (max-width: 768px) {
  .HomeMainContainer {
    .container {
      flex-direction: column;
    }
  }

  // .CatOuterContainer {
  //   &:not(:last-of-type) {
  //     padding-bottom: 0;
  //   }
  // }

  .ProductCardGridContainer {
    padding-top: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}
