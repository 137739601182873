.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 2rem;
  border-radius: 0.5rem;
  width: 90%;
  max-width: 500px;
  position: relative;
  animation: modalFadeIn 0.3s ease;
  height: auto;
}

.bid-form {
  .input-group {
    input {
      width: 100% !important;
    }
  }
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.close-button {
  position: absolute;
  right: 5px;
  top: 10px;
  border: none;
  background: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #666;

  &:hover {
    color: #000;
  }
}

.modal-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.current-price {
  background-color: #f8f8f8;
  padding: 1rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;

  .price {
    font-size: 1.25rem;
    font-weight: 600;
  }
}

.bid-form {
  .input-group {
    margin-bottom: 1rem;

    label {
      display: block;
      margin-bottom: 0.5rem;
      font-weight: 500;
    }

    .input-wrapper {
      position: relative;

      .currency {
        position: absolute;
        left: 1rem;
        top: 50%;
        transform: translateY(-50%);
        color: #666;
      }

      input {
        width: calc(100% - 3rem);
        padding: 0.75rem 1rem 0.75rem 2rem;
        border: 1px solid #ddd;
        border-radius: 0.25rem;
        font-size: 1rem;

        &:focus {
          outline: none;
          border-color: #2563eb;
        }
      }
    }
  }

  .error-message {
    color: #dc2626;
    font-size: 0.875rem;
    margin-top: 0.5rem;
    display: block;
    text-align: start;
  }
}

.bid-info {
  margin: 1rem 0;

  p {
    color: #666;
    font-size: 0.875rem;
    margin: 0.25rem 0;
    text-align: left;
  }
}

.submit-button {
  background-color: #1a1a1a;
  color: white;
  padding: 0.75rem;
  border: none;
  border-radius: 0.25rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #333;
  }
}
