.Login {
  .container {
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;

    .Login__form {
      padding: 75px 20px 0;
      padding-top: 75px;
      display: flex;
      flex-direction: column;
      gap: 30px;
    }

    .Login__inner {
      // padding: 30px 0;
      min-height: 100vh;

      .themeBrand {
        display: block;
        width: 250px;
        margin: 0 auto;
      }

      .Login__title {
        text-align: center;
      }

      .form-group {
        display: flex;
        align-items: center;
        gap: 15px;
        border-bottom: 1px solid #333333;
        padding-bottom: 15px;

        input {
          background-color: transparent !important;
          border: 0;
          flex: 1;
          outline: none;

          &:-internal-autofill-selected {
            background-color: transparent !important;
          }
        }
      }

      .error-message{
        font-size: 14px;
        color: #ee4747;
      }

      .forgotPass {
        text-align: end;
        text-decoration: none;
        color: #333333;
        font-weight: 800;
      }
    }

    .login-btn {
      display: flex;
      justify-content: center;

      button {
        padding: 8px 20px !important;
        min-width: 153px;
        text-align: center;
        justify-content: center;
        border: none;
        border-radius: 7px;
        background-color: #333333;
        color: #fff;

      }
    }

    .content-text {
      padding-top: 65px;
      text-align: center;
      font-weight: 600;
    }
    .separatorText {
      display: block;
      text-align: center;
      margin-top: 45px;
      position: relative;

      span {
        position: relative;
        z-index: 2;
        background-color: #fff;
        padding: 0 5px;
      }

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #131313;
        z-index: -1;
      }
    }

    .social-icons {
      display: flex;
      justify-content: center;
      gap: 50px;
      padding-top: 40px;

      button {
        background-color: transparent;
        border: none;
      }
    }

    .signup-link {
      text-align: center;
      padding-top: 40px;

      a {
        text-decoration: none;
        color: #131313;
        font-weight: 700;
      }
    }
  }
}
