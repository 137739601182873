.FavouriteMainWrapper {
  padding-top: 20px;

  .search-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 9px;
    width: 100%;
    margin-top: 20px;

    input {
      border: none;
      background-color: transparent;
      font-size: 14px;
      width: calc(100% - 48px);
      outline: none;
    }

    .search-span-icon {
      font-size: 24px;
      color: #bdbdbd;
    }

    .filter_btn {
      padding: 0;
      border: none;
      background: transparent;

      svg,
      path {
        fill: #333333;
      }
    }
  }
}
.CartPageMainWrapper {
  .search-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 9px;
    width: 100%;
    margin-top: 20px;

    input {
      border: none;
      background-color: transparent;
      font-size: 14px;
      width: calc(100% - 48px);
      outline: none;
    }

    .search-span-icon {
      font-size: 24px;
      color: #bdbdbd;
    }

    .filter_btn {
      padding: 0;
      border: none;
      background: transparent;

      svg,
      path {
        fill: #333333;
      }
    }
  }
}
