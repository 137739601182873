.sectionHeader {
  display: grid;
  gap: 10px;

  h3 {
    font-size: 22px;
    font-weight: 600;
  }
  button {
    width: fit-content !important;
  }
}
