.JourneyMainWrapper {
  padding: 50px 0;

  .introduction {
    max-width: 1024px;
    width: 100%;
    margin: 0 auto;

    .section-title {
      font-size: 28px;
      text-align: center;
    }
    .intro-text {
      font-size: 18px;
      text-align: center;
      padding-top: 10px;
    }
  }

  .timeline {
    .section-title {
      text-align: center;
      padding-top: 40px;
      padding-bottom: 20px;
    }

    .timeline-item {
      padding: 20px;
      border-radius: 20px;
      background-color: #fff;
      max-width: 768px;
      width: 100%;
      margin: 0 auto;
      background-color: #f3f3f3;

      display: flex;
      align-items: flex-start;
      gap: 10px;

      &:not(:last-of-type) {
        margin-bottom: 20px;
      }

      .timeline-date {
        padding: 8px;
        border-radius: 10px;
        background-color: #fff;
      }
    }
  }

  .gallery {
    .section-title {
      text-align: center;
      padding-top: 40px;
      padding-bottom: 20px;
    }

    .gallery-grid {
      padding-top: 40px;

      display: grid;
      grid-template-columns: repeat(4, 1fr);

      .gallery-item {
        width: 100%;
        height: 100%;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .additional-content {
    padding-top: 40px;

    .quote {
      padding: 20px;
      width: 60%;
      margin: 0 auto;
      background-color: #f3f3f3;
      border-left: 4px solid #000;
      border-radius: 10px;
      font-style: italic;
      text-align: center;
    }

    .team {
      h3 {
        text-align: center;
        padding-top: 40px;
        padding-bottom: 20px;
      }

      & > p {
        text-align: center;
        font-weight: 500;
      }

      .team-members {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        padding-top: 40px;

        .team-member {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          img {
            width: 150px;
            aspect-ratio: 1/1;
            object-fit: cover;
            border-radius: 50%;
          }

          p {
            font-weight: 600;
            margin-top: 20px;
          }
        }
      }
    }
  }

  .cta {
    background-color: #131313;
    color: #fff;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    margin-top: 60px;
    text-align: center;
    border-radius: 15px;

    a {
      display: inline-block;
      background-color: #fff;
      color: #000;
      text-decoration: none;
      padding: 10px 20px;
      margin-top: 20px;
    }
  }
}

@media (max-width: 768px) {
  .JourneyMainWrapper {
    .gallery {
      .gallery-grid {
        grid-template-columns: repeat(2, 1fr);
      }
    }
    .additional-content {
      .quote {
        width: 100%;
      }
    }

    .additional-content {
      .team {
        .team-members {
          grid-template-columns: 1fr;
          gap: 40px;
        }
      }
    }

    .cta{
      p{
        padding-top: 20px;
      }
    }
  }
}

@media (max-width: 640px) {
  .JourneyMainWrapper {
    .gallery {
      .gallery-grid {
        grid-template-columns: 1fr;
        .gallery-item {
        }
      }
    }
  }
}
