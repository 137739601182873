$primary-color: #2d2d2d;
$accent-color: #fff;
$light-gray: #f4f4f4;
$input-bg: #fff;

.footer-image {
  display: flex;
  justify-content: start;
  gap: 10px;

  img {
    object-fit: contain;
    width: 100%;

    height: 100%;
  }
}

.footer {
  background-color: $primary-color;
  color: $light-gray;
  padding: 2rem 1rem;
  // margin-top: 10px;

  &__container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2rem;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  &__brand {
    max-width: 300px;
    margin-top: -23px;

    h2 {
      color: white;
      font-size: 1.7rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }
    p {
      font-size: 0.9rem;
      line-height: 1.5;
      color: #cfcfcf;
      text-align: start;
    }
  }

  &__links {
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 1rem;
    }

    h4 {
      color: $accent-color;
      font-size: 1.1rem;
      margin-bottom: 0.5rem;
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        margin-bottom: 0.5rem;
        font-size: 0.9rem;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .footer__linksContainer {
      display: flex;
      gap: 250px;
    }
  }

  &__demo {
    flex: 1;
    max-width: 300px;

    .footer__input-wrapper {
      position: relative;
      display: flex;
      align-items: center;

      .footer__input {
        width: 100%;
        padding: 0.8rem 1.2rem;
        font-size: 1rem;
        border: 2px solid transparent;
        border-radius: 50px;
        outline: none;
        transition: all 0.3s ease;
        background-color: #f8f8f8;
        color: #333;

        &::placeholder {
          color: #aaa;
          font-style: italic;
        }

        &:hover {
          border-color: #ff6700;
        }

        &:focus {
          border-color: #ff6700;
          box-shadow: 0 0 8px rgba(255, 103, 0, 0.5);
          background-color: #fff;
        }
      }

      .footer__button {
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        background-color: #ff6700;
        color: #fff;
        border: none;
        border-radius: 50px;
        padding: 0.7rem 1rem;
        font-size: 1.2rem;
        cursor: pointer;
        transition: all 0.3s ease;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

        &:hover {
          background-color: darken(#ff6700, 10%);
          transform: translateY(-50%) scale(1.05); // Scale animation
          box-shadow: 0 6px 10px rgba(255, 103, 0, 0.3);
        }

        &:active {
          transform: translateY(-50%) scale(0.95); // Pressed animation
        }
      }
    }

    h4 {
      margin-bottom: 1rem;
      color: $accent-color;
      font-size: 1.2rem;
      font-weight: bold;
    }

    &__input-wrapper {
      display: flex;
      align-items: center;
      position: relative;

      .footer__input {
        width: 100%;
        padding: 0.8rem 1rem;
        font-size: 0.9rem;
        border: none;
        outline: none;
        border-radius: 50px;
        background-color: $input-bg;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      }

      .footer__button {
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        background-color: $accent-color;
        color: white;
        border: none;
        padding: 0.6rem 1rem;
        border-radius: 50px;
        font-size: 1.1rem;
        cursor: pointer;

        &:hover {
          background-color: darken($accent-color, 10%);
        }
      }
    }
  }

  &__bottom {
    border-top: 1px solid #555;
    margin-top: 2rem;
    padding-top: 1rem;
    text-align: center;
    font-size: 0.8rem;

    p {
      color: rgb(133, 133, 133);
    }

    a {
      color: $light-gray;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.footer-image-logo {
  text-decoration: none !important;
  color: white !important;
}

.social-links {
  display: flex;
  gap: 15px;
  padding-top: 10px;

  a {
    width: 25px;
    aspect-ratio: 1/1;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

@media (max-width: 1080px) {
  .footer__links {
    .footer__linksContainer {
      gap: 150px;
    }
  }
}

@media (max-width: 768px) {
  .footer__links {
    .footer__linksContainer {
      gap: 40px;
      flex-direction: column;
    }
  }
}
