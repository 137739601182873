.SingleItemCard {
  padding: 10px;
  border: 1px solid #ededed;
  border-radius: 10px;
  position: relative;
  cursor: pointer;

  .IconBtn {
    position: absolute;
    right: 15px;
    top: 15px;
    min-width: 35px !important;
    height: 35px !important;
    background-color: #ec3f3f36 !important;
    border: 1px solid #e66363;

    svg {
      line-height: 0;
      stroke: #e66363;
    }
  }

  .SingleItemCardImage {
    width: 100%;
    height: 250px;
    border-radius: 7px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .SingleItemCardContent {
    padding: 15px 0;
  }

  .SingleItemCardName {
    font-size: 18px;
    line-height: 1.2;
    font-weight: 600;
  }

  .SingleItemCardDescription {
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 300px;
    text-align: left;
  }

  // &.disabled {
  //   pointer-events: none;
  //   background-color: rgba(190, 187, 187, 0.1); // Gray background
  //   opacity: 0.6; // Faded look
  // }

  // .SingleItemCardImage,
  // .SingleItemCardContent {
  //   filter: grayscale(50%);
  // }

  .payment-status {
    position: absolute;
    top: 15px;
    left: 15px;
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 35px;
    font-family: "poppins" !important;
    text-transform: capitalize !important;
    z-index: 999;

    &.pending {
      background-color: #ec3f3f;
      color: #fff;
    }
  }
}

@media (max-width: 1380px) {
  .SingleItemCard {
    .SingleItemCardDescription {
      font-size: 14px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 150px;
    }
  }
}

@media (max-width: 1150px) {
  .SingleItemCard {
    .SingleItemCardImage {
      height: 180px;
    }
  }
}

@media (max-width: 768px) {
  .SingleItemCard {
    padding: 7.5px;
    .SingleItemCardImage {
      height: 115px;
    }
  }

  .SingleItemCard {
    .SingleItemCardName {
      font-size: 12px;
    }

    .SingleItemCardDescription {
      font-size: 12px;
    }
  }
}

@media (max-width: 380px) {
  .SingleItemCard {
    .SingleItemCardDescription {
      width: 100px;
    }
  }
}
