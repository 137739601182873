.property-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 35px;

  .back-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1.25rem;
    background: none;
    border: none;
    cursor: pointer;
    color: #666;

    &:hover {
      color: #333;
    }
  }
}

.property-grid {
  display: grid;
  grid-template-columns: 1fr 350px;
  gap: 1.5rem;
  overflow: auto;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
}

.main-content {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  .image-gallery {
    margin-bottom: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
    overflow: auto;
    height: 200px;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px #3333331b;
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #33333344;
    }

    .main-image {
      margin-bottom: 0.5rem;
      border-radius: 0.5rem;
      overflow: hidden;

      img {
        width: 100%;
        height: auto;
        max-width: 300px;
        object-fit: cover;
      }
    }

    .thumbnail-grid {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 0.5rem;

      .thumbnail {
        border-radius: 0.5rem;
        overflow: hidden;
        position: relative;

        img {
          width: 100%;
          height: auto;
          max-height: 80px;
          object-fit: cover;
        }

        &.overlay {
          &::after {
            content: "";
            position: absolute;
            inset: 0;
            background: rgba(0, 0, 0, 0.5);
          }

          .overlay-text {
            position: absolute;
            inset: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            font-size: 1.25rem;
            font-weight: bold;
            z-index: 1;
          }
        }
      }
    }
  }

  .property-details {
    margin-bottom: 1.5rem;
    h1 {
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }

    .features {
      display: flex;
      flex-wrap: wrap;
      gap: 1.5rem;
      margin-bottom: 1rem;

      .feature {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        color: #666;

        svg {
          font-size: 1.25rem;
        }
      }
    }
  }

  .tabs {
    display: flex;
    gap: 1.5rem;
    border-bottom: 1px solid #e5e7eb;
    margin-bottom: 1.5rem;

    .tab {
      padding: 0.75rem 1rem;
      border: none;
      background: none;
      cursor: pointer;
      color: #666;
      position: relative;

      &.active {
        color: #2563eb;
        font-weight: 500;

        &::after {
          content: "";
          position: absolute;
          bottom: -1px;
          left: 0;
          right: 0;
          height: 2px;
          background: #2563eb;
        }
      }

      &:hover:not(.active) {
        color: #333;
      }
    }
  }

  .description {
    color: #666;
    line-height: 1.6;
    overflow-wrap: break-word;

    p {
      margin-bottom: 1rem;
    }
  }
}

.sidebar {
  overflow: auto;
  height: fit-content;

  .MuiCard-root {
    height: fit-content;
  }

  .price-section {
    margin-bottom: 1.5rem;

    .price {
      color: black;
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 0.25rem; /* 4px */
    }

    .price-subtitle {
      color: #666;
      font-size: 0.875rem;
      margin-bottom: 0.5rem;
    }

    .market-price {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .original-price {
        font-size: 1.25rem;
        font-weight: bold;
      }

      .discount {
        color: #686868;
        font-size: 0.875rem;
      }
    }
  }

  .agent-section {
    display: flex;
    align-items: center;
    gap: 1rem; /* 16px */
    margin-bottom: 1.5rem; /* 24px */

    .agent-image {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      object-fit: cover;
    }

    .agent-info {
      h3 {
        font-weight: 500;
        margin-bottom: 0.25rem; /* 4px */
      }

      p {
        color: #666;
        font-size: 0.875rem;
      }
    }
  }

  .action-buttons {
    display: flex;
    gap: 0.5rem; /* 8px */
    height: fit-content;

    button {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem; /* 8px */
      padding: 0.5rem 1rem; /* 8px 16px */
      border-radius: 0.5rem; /* 8px */
      cursor: pointer;
      font-weight: 500;
      transition: background-color 0.2s;

      svg {
        font-size: 1.25rem;
      }
    }

    // .btn-call {
    //   background: #2563eb;
    //   color: white;

    //   &:hover {
    //     background: #1d4ed8;
    //   }
    // }

    // .btn-message {
    //   background: white;
    //   border: 1px solid #2563eb;
    //   color: #2563eb;

    //   &:hover {
    //     background: #f8fafc;
    //   }
    // }
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalContent {
  background: #fff;
  padding: 0;
  border-radius: 0.5rem;
  max-width: 900px;
  width: calc(100% - 20px);
  margin: 10px;
  position: relative;
  overflow: auto;
  max-height: 90vh;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.productDetail-model .productDetail__img .productImg-gallery {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;
  background: transparent;
  border: none;
  height: auto;
  border-radius: 0;
  margin: 0;
  & > div {
    border-radius: 8px;
    border: 1px solid #ccc;
    background: #f5f5f5;
    overflow: hidden;
    height: 100px;
    img {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }

  & > div:first-child {
    // grid-column-start: 1;
    // grid-column-end: 4;
    // height: auto !important;
    // img {
    //   width: 100%;
    //   height: 100% !important;
    // }
  }
}
.productDetail-model {
  .price-subtitle {
    font-size: 16px;
    margin: 0 0 5px;
    font-weight: 700;
  }
  .productDetail__inner {
    grid-template-columns: 1fr 1fr;
    @media only screen and (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }
}
