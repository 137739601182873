.tnd-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.tnd-modal-content {
  background: white;
  border-radius: 8px;
  margin: 10px;
  width: calc(100% - 20px);
  max-width: 1200px;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.tnd-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #ddd;
}

.tnd-modal-header h2 {
  margin: 0;
}

.tnd-close-button {
  background: none;
  border: none;
  font-size: 35px;
  line-height: 1;
  cursor: pointer;
}

.tnd-modal-body {
  padding: 15px 32px;
  flex: 1;
  overflow: auto;
  @media only screen and (max-width: 767px) {
    padding: 15px 20px;
  }

  h2 {
    padding-top: 10px;
    border-bottom: 1px solid #dddddd;
    margin-bottom: 14px;
  }

  h3 {
    padding: 0 0 10px;
  }

  p {
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    padding: 5px 0;
  }

  ul {
    padding-bottom: 20px;
    padding-left: 20px;
    display: grid;
    gap: 10px;

    li {
      p {
        padding: 0;
      }
    }
  }
}

/* Modal footer */
.tnd-modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding: 1rem;
  border-top: 1px solid #ddd;
}

.tnd-modal-checkbox {
  // margin-top: 1rem;
  label {
    font-size: 0.9em;
    color: #555;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
  }
}

/* Buttons */
.tnd-modal-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.tnd-modal-button.primary {
  background-color: #007bff;
  color: white;
}

.tnd-modal-button.primary:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.tnd-modal-body .tnd-modal-checkbox input {
  width: auto;
}

.tnd-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
}

.tnd-modal-content {
  position: relative;
  background: white;
  max-height: 90vh;
  width: 90%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;

  @media only screen and (max-width: 767px) {
    max-height: 70vh;
  }
}

.tnd-modal-body {
  overflow-y: auto;
  flex-grow: 1;
  padding: 20px;
}

.tnd-modal-header,
.tnd-modal-footer {
  position: sticky;
  background: white;
  padding: 15px;
}

.tnd-modal-header {
  top: 0;
}

.tnd-modal-footer {
  bottom: 0;
  border-top: 1px solid #eee;
}
