.date-placeholder {
  font-weight: 600 !important;
}

.SellerWrapper {
  .productForm__inner {
    align-items: flex-start !important;

    .ImageUpload{
      margin-top: 10px;
    }
  }
  .productForm {
    .inputUpload.profile_image {
      max-width: calc(50% - 8px);
      width: 100%;
      margin-top: 20px;
    }
  }

  .form-group.PasswordContainer {
    display: flex;
    align-items: center;
    gap: 10px;

    border: 1px solid #e2e2e2;
    border-radius: 10px;
    font-size: 15px;
    line-height: 24px;
    padding: 8.5px 20px;
    width: 100%;

    input {
      border: unset;
      border-radius: 0;
      padding: 0;
      width: 100%;
      outline: none;
    }

    .EyeIcon {
      cursor: pointer;
    }
  }
  .sellerInfo {
    padding-top: 40px;
    h4 {
      padding-bottom: 20px;
    }
  }
  .productForm__inner {
    padding-top: 20px;
  }
  .SellerFormWrapper {
    padding-top: 40px;

    &.PaymentInfo {
      .productForm__inner {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
  .SellerFormWrapperInner {
    padding-top: 20px;

    label {
      padding-bottom: 5px;
      display: block;
    }
  }
  .btnPart {
    padding-top: 40px;
    text-align: end;
  }
  input[type="checkbox"] {
    width: 20px;
    height: 15px;
  }
  .error-message {
    color: red;
  }
}

@media (max-width: 991px) {
  .SellerWrapper {
    .productForm {
      .inputUpload.profile_image {
        max-width: 100%;
      }
    }

    .SellerFormWrapper {
      &.PaymentInfo {
        .productForm__inner {
          grid-template-columns: 1fr;
        }
      }
    }
    .SellerFormWrapperInner {
      label {
        display: flex;
        align-items: center;
        gap: 5px;
        line-height: 1;

        input {
        }
      }
    }
  }
}
