.EditProductModal {
  &.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100dvh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  .modalcontent {
    background: white;
    border-radius: 12px;
    max-width: 600px;
    width: 100%;
    padding: 40px;
    height: auto;
    position: relative;
    max-height: 90vh;
    overflow: auto;

    display: flex;
    flex-direction: column;
    gap: 20px;

    
&::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #3333331b;
  background-color: #f5f5f5;
}
&::-webkit-scrollbar {
  width: 6px;
}
&::-webkit-scrollbar-thumb {
  background-color: #33333344;
}


    @media (max-width: 991px) {
      padding: 30px;
    }

    h2 {
      margin: 0 0 5px;
      font-size: 22px;
      line-height: 32px;
      color: #333;
      font-weight: 700;
      @media (max-width: 991px) {
        font-size: 18px;
        line-height: 26px;
        margin: 0 0 20px;
      }
    }
    .modal-actions {
      display: flex;
      gap: 15px;
      align-items: center;
      justify-content: start;
      button {
        min-width: 110px;
      }
    }
    .form-row {
      display: grid;
      gap: 20px;
      grid-template-columns: repeat(2, 1fr);
    }
    .form-row-three {
      display: grid;
      gap: 20px;
      grid-template-columns: 1fr 1fr 1fr;
    }

    .form-group {
      display: flex;
      flex-direction: column;

      input {
        border: 1px solid #e2e2e2 !important;
        border-radius: 10px;
        font-size: 15px;
        line-height: 24px;
        padding: 12px 20px;
      }
      textarea {
        border: 1px solid #e2e2e2;
        border-radius: 10px;
        font-size: 15px;
        line-height: 24px;
        padding: 12px 20px;
        width: 100%;
        &:focus-visible,
        &:focus {
          box-shadow: unset;
          outline: none;
        }
      }
    }

    .form-group label {
      font-weight: 600;
      margin-bottom: 8px;
    }

    .file-preview {
      margin-bottom: 15px;
    }

    .file-preview-item {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .file-preview-item img {
      width: 80px;
      height: 80px;
      object-fit: cover;
      border-radius: 8px;
    }
    .uploadedImg {
      display: grid;
      gap: 20px;
      grid-template-columns: 1fr 1fr;
      margin-top: 20px;
      @media (max-width: 767px) {
        grid-template-columns: 1fr;
      }
      .imgPreview {
        grid-template-columns: 75px auto;
        gap: 16px;
        margin-bottom: 0;
        margin-top: 0;
        border: 1px solid #eee;
        border-radius: 8px;
        padding: 8px;
      }
      .imgPreview__inner {
        height: 75px;
        box-sizing: border-box;
        background: #f5f5f5;
      }
      .imgPreview__content {
        align-items: stretch;
        justify-content: center;
        text-align: start;
      }
    }
  }
  .modal-actions.btnPart{
    justify-content: flex-end;
  }
  

}



@media (max-width: 640px) {
  .EditProductModal {
    .modalcontent {
      .form-row {
        grid-template-columns: 1fr;
      }
    }

    .modalcontent {
      .form-row-three {
        grid-template-columns: 1fr;
      }
    }
  }
}
