.sold-product-card a {
  display: block;
  padding: 15px;
  border-radius: 25px;
  border: 1px solid #e2e2e2;

  .payment-status {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 35px;
    font-family: "poppins" !important;
    text-transform: capitalize !important;

    &.pending {
      background-color: #ec3f3f;
      color: #fff;
    }

    &.paid {
      background-color: #3fec81;
      color: #131313;
    }
  }

  .product-image {
    position: relative;
    border-radius: 15px;
    width: 100%;
    height: 200px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .product-details {
    padding-top: 10px;

    h3 {
      font-family: "Montserrat", sans-serif !important;
      padding-bottom: 0 !important;
      color: #131313;
      font-size: 25px;
      font-weight: 600;
    }
    p {
      margin-top: 0;
      font-weight: 400;
      text-align: left;
    }

    .total-price {
      font-weight: 600;
    }
  }
}

@media (max-width: 640px) {
  .sold-product-card a {
    padding: 10px;

    .product-image {
      height: 115px;
    }

    .product-details{
      h3{
        font-size: 20px;
      }

      p{
        font-size: 14px;
      }
    }

    .payment-status {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 10px;
    }
  }
}
