.schedule-modal {
  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  &-container {
    width: 100%;
    height: fit-content;
    max-width: 42rem;
    margin: 0 auto;
    padding: 1rem;
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    overflow: auto;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar {
      width: 4px;
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #000000;
      border: 2px solid #555555;
    }
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #e5e7eb;

    h2 {
      font-size: 1.25rem;
      font-weight: 600;
    }

    .icon {
      width: 1.5rem;
      height: 1.5rem;
      cursor: pointer;
      color: black;
    }
  }

  &-description {
    color: #4b5563;
    margin-bottom: 1.5rem;
  }

  &-time-periods {
    display: grid;
    grid-template-columns: 2fr repeat(3, 1fr);
    gap: 0.5rem;
    margin-bottom: 1.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #e5e7eb;

    .period-label {
      text-align: center;
      font-weight: 500;
      background-color: black;
      color: white;
      padding: 0.25rem 0.5rem;
      border-radius: 0.25rem;
    }
  }

  &-day-row {
    display: grid;
    grid-template-columns: 2fr repeat(3, 1fr);
    gap: 0.5rem;
    margin-bottom: 1rem;
    align-items: center;

    .day-label {
      font-weight: 500;
      color: #1f2937;
    }

    .time-slot-group {
      display: flex;
      gap: 0.25rem;

      &.morning {
        grid-column: 2;
      }
      &.afternoon {
        grid-column: 3;
      }
      &.evening {
        grid-column: 4;
      }
    }
  }

  &-time-slot {
    flex: 1;
    padding: 0.25rem;
    font-size: 0.875rem;
    border-radius: 0.25rem;
    border: none;
    background-color: #f3f4f6;
    cursor: pointer;
    transition: all 0.2s ease;
    text-wrap: nowrap;

    &:hover {
      background-color: #e5e7eb;
    }

    &.selected {
      background-color: black;
      color: white;
    }
  }

  &-save-button {
    // width: 100%;
    margin-top: 1.5rem;
    padding: 0.5rem 1rem;
    background-color: rgb(20, 20, 20);
    color: white;
    border: none;
    border-radius: 0.5rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: #000000;
    }
  }
}

.time-slot-group__name {
  display: none;
}
// Responsive styles
@media (max-width: 768px) {
  .time-slot-group__name {
    display: block;
  }

  .schedule-modal {
    &-container {
      max-width: 95%;
      padding: 0.75rem;
    }

    &-day-row {
      gap: 0.25rem;
    }

    &-time-slot {
      padding: 0.25rem;
      font-size: 0.75rem;
    }
  }
}

@media (max-width: 640px) {
  .schedule-modal {
    &-time-periods {
      .period-label {
        font-size: 0.75rem;
        padding: 0.25rem;
      }
    }

    &-day-row {
      .day-label {
        font-size: 0.875rem;
      }
    }
  }
}
